import React, { useRef } from "react"
import { connect } from "react-redux"
import {
  Flex,
  Heading,
  Text,
  Link,
  Button,
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from "@chakra-ui/react"

import { scoreReset } from "../../redux/actions/score"

const ScoreIntro = ({ dispatch }) => {
  //=================================================================
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  //-----------------------------------------------------------------
  const onClickResetScore = () => {
    dispatch(scoreReset())
    onClose()
  }

  //=================================================================
  return (
    <>
      <Flex justify="space-between">
        <Heading mr={4}>Project Work Best Practices Score</Heading>
        <Button onClick={onOpen}>Reset Score</Button>
      </Flex>
      <Text mr={4}>
        Not every small project requires a systematic approach to project
        management and control. But certainly for projects that are{" "}
        <em>important</em> (and expensive) for the company this is sensible. A
        structured approach increases the likelihood of project success and
        achieves a more efficient use of the available resources. You can test
        how mature your company is with respect to Project Management and
        Control. The Best Practices Score will be within a range of 0−100%. It
        is based on the{" "}
        <Link
          href="https://project-status.org/blog/best-practices"
          isExternal
          color="blue.800"
        >
          Best Practices Framework
        </Link>{" "}
        that you will find below. Please assess your company with the questions
        below. If you feel that a question is <em>not applicable</em> to your
        context, just click <em>n.a.</em> and this question will not be
        considered in the score calculation.
      </Text>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Reset Questionnaire?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to reset the questionnaire?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button color="white" bg="red" ml={3} onClick={onClickResetScore}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default connect()(ScoreIntro)
