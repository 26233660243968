import cloneDeep from "lodash.clonedeep"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Re-constructs the roles in setup and in persons from
 * rolesToPersons
 * @param {array} rolesStandard
 * @param {object} status
 * @param {object} status
 */
function statusRolesReconstruct(rolesStandard, status) {
  let rolesStandardFiltered = rolesStandard.filter(
    (roleStandard) =>
      roleStandard.value !== "memberTeam" && roleStandard.value !== "memberStC"
  )

  // reset roles for each person
  Object.keys(status.persons).forEach((sId) => (status.persons[sId].roles = []))

  // reset all roles in setup to "" or []
  rolesStandardFiltered.forEach((role) => (status.setup[role.value] = ""))
  status.setup.membersTeam = []
  status.setup.membersStC = []

  // re-construct everything from rolesToPersons
  status.rolesToPersons.forEach((roleToPerson) => {
    // re-construct each role in setup
    if (roleToPerson.rId !== "memberTeam" && roleToPerson.rId !== "memberStC") {
      status.setup[roleToPerson.rId] = roleToPerson.sId
    } else if (roleToPerson.rId === "memberTeam") {
      status.setup.membersTeam.push({
        value: roleToPerson.sId,
        label: status.persons[roleToPerson.sId].nameDisplayShort,
      })
    } else if (roleToPerson.rId === "memberStC") {
      status.setup.membersStC.push({
        value: roleToPerson.sId,
        label: status.persons[roleToPerson.sId].nameDisplayShort,
      })
    }
    // re-construct roles for each person
    status.persons[roleToPerson.sId].roles.push(
      rolesStandard.filter(
        (roleStandard) => roleStandard.value === roleToPerson.rId
      )[0]
    )
  })

  return status
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Sets rolesToPersons triggered by Form Setup
 * @param {object} rolesStandards
 * @param {object} status
 * @param {string} nameRole
 * @return {object} statusNew
 */
export function rolesToPersonsSet_Setup(rolesStandard, status, nameRole) {
  let statusNew = cloneDeep(status)
  let selected = []

  // filter out all that had "nameRole" previously
  statusNew.rolesToPersons = statusNew.rolesToPersons.filter(
    (roleToPerson) => roleToPerson.rId !== nameRole
  )

  // rolesStandard = [{value: "customer1", label: "Customer 1"}, ...]
  // filter out "memberTeam" and "memberStC"
  let rolesStandardFiltered = rolesStandard.filter(
    (roleStandard) =>
      roleStandard.value !== "memberTeam" && roleStandard.value !== "memberStC"
  )

  // generate selected = [{rId: "manager", sId: "ol9kzh"}, ...]
  rolesStandardFiltered.forEach((roleStandard) => {
    // get person sId in this roleStandard, i.e. customer 1: ...
    const sId = statusNew.setup[roleStandard.value]

    if (sId !== undefined && sId !== "") {
      selected.push({ rId: roleStandard.value, sId })
    }
  })

  // add teamMembers to selected = [{rId: "memberTeam", sId: "dr6tiu"}, ...]
  statusNew.setup.membersTeam.forEach((el) => {
    selected.push({ rId: "memberTeam", sId: el.value })
  })

  // add stcMembers to selected = [{rId: "memberStC", sId: "ve2plm"}, ...]
  statusNew.setup.membersStC.forEach((el) => {
    selected.push({ rId: "memberStC", sId: el.value })
  })

  // remove duplicates
  statusNew.rolesToPersons = rolesAssignedKeepLatest([
    ...statusNew.rolesToPersons,
    ...selected,
  ])

  statusNew = statusRolesReconstruct(rolesStandard, statusNew)

  return statusNew
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Sets rolesToPersons triggered from Persons, e.g. Team or Steering Committee
 * @param {object} status
 * @param {string} selected
 * @return {object} statusNew
 */
export function rolesToPersonsSet_Persons(
  rolesStandard,
  status,
  rolesToPersonsSelected = [],
  rId
) {
  let statusNew = cloneDeep(status)

  // clean roleToPersons from al elements with "memberTeam"
  if (rId === "memberTeam") {
    statusNew.rolesToPersons = statusNew.rolesToPersons.filter(
      (roleToPerson) => roleToPerson.rId !== "memberTeam"
    )
  }
  // clean roleToPersons from al elements with "stdMember"
  if (rId === "memberStC") {
    statusNew.rolesToPersons = statusNew.rolesToPersons.filter(
      (roleToPerson) => roleToPerson.rId !== "memberStC"
    )
  }

  statusNew.rolesToPersons = rolesAssignedKeepLatest([
    ...statusNew.rolesToPersons,
    ...rolesToPersonsSelected,
  ])

  statusNew = statusRolesReconstruct(rolesStandard, statusNew)

  return statusNew
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Sets rolesToPersons triggered from a Person
 * @param {object} status
 * @param {string} selected
 * @return {object} statusNew
 */
export function rolesToPersonsSet_Person(
  rolesStandard,
  status,
  rolesToPersonsSelected = [],
  sId
) {
  let statusNew = cloneDeep(status)

  // clean person from rolesToPersons

  statusNew.rolesToPersons = statusNew.rolesToPersons.filter(
    (roleToPerson) => roleToPerson.sId !== sId
  )

  statusNew.rolesToPersons = rolesAssignedKeepLatest([
    ...statusNew.rolesToPersons,
    ...rolesToPersonsSelected,
  ])

  statusNew = statusRolesReconstruct(rolesStandard, statusNew)

  return statusNew
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Filter all duplicates and overrides from rolesAssigned
 * and only keep latest entry
 * @param {array} rolesToPersons
 * @return {array} rolesToPersonsNew
 */
export function rolesAssignedKeepLatest(rolesToPersons) {
  // https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects?page=1&tab=votes#tab-top
  const rolesToPersonsNew = rolesToPersons
    .slice()
    .reverse()
    // filter out duplicates of individual roles
    .filter((roleToPerson, index, self) => {
      const foundAtIndex = self.findIndex((el) => el.rId === roleToPerson.rId)

      // found at own index
      // keep
      if (foundAtIndex === index) {
        return true
      }
      // do not filter yet
      else if (
        roleToPerson.rId === "memberTeam" ||
        roleToPerson.rId === "memberStC"
      ) {
        return true
      }
      // do not keep
      else {
        return false
      }
      // return false
    })
    // filter out duplicates of team roles, e.g. memberTeam and memberStC
    .filter((roleToPerson, index, self) => {
      const foundAtIndex = self.findIndex(
        (el) => el.rId === roleToPerson.rId && el.sId === roleToPerson.sId
      )
      if (foundAtIndex === index) {
        return true
      } else {
        return false
      }
    })
    .reverse()

  return rolesToPersonsNew
}
