/**
 * https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 *
 * Generates a hash from a string
 * @param {string} str
 * @return {string} hash
 */

export const shortHashGen = (s) => {
  for (var i = 0, h = 9; i < s.length; )
    h = Math.imul(h ^ s.charCodeAt(i++), 9 ** 9)
  const result = h ^ (h >>> 9)
  return Math.abs(result).toString(16).padStart(8, 0)
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 *
 * @param {string} str
 * @returns {string} str with a pint after 4 chars
 */
export const addPoint = (str) => str.slice(0, 4) + "." + str.slice(4, 8)
