const styles = {
  global: () => ({
    "*": {
      boxSizing: "border-box",
      fontWeight: "normal",
      fontSize: "md",
    },
    body: {},
    a: { color: "blue.800", _hover: { color: "blue.600" } },
    ".react-icons": {
      position: "relative",
      top: "5px",
    },
  }),
}

export default styles
