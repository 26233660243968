import nanoId from "../utils/nanoid"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Creates an empty person.
 * @param {string} sId - person Id, if not set it gets a nanoId()
 * @returns {object} personNew - person created
 */
const personCreate = (sId) => {
  let personNew = {
    sId: sId ? sId : nanoId(),
    nameLast: "",
    nameFirst: "",
    nameDisplayShort: "",
    nameDisplayLong: "",
    academicTitle: "",
    company: "",
    jobFunction: "",
    roles: [],
    email: "",
    phone: "",
    url: "",
    avatar: "",
  }
  return personNew
}

export default personCreate
