import React from "react"
import { chakra, Flex, Box, Spacer } from "@chakra-ui/react"
import {
  BiPlus,
  BiMinus,
  BiLeftArrowAlt,
  BiUpArrowAlt,
  BiRightArrowAlt,
  BiDownArrowAlt,
} from "react-icons/bi"
import { MdClose } from "react-icons/md"
import {
  nodeNotActiveUpdate,
  rowCreate,
  rowDelete,
  rowMoveIn,
  rowMoveOut,
  rowMoveUpDown,
} from "../../redux/actions/project"
import { ROOT } from "../../const/globals"

const Component = ({ node, isTop = false, isBottom = false, dispatch }) => {
  //=================================================================

  const { nId, position } = node

  const isMovedIn = position.length > 1

  const isLocked = node.isLocked

  //-----------------------------------------------------------------

  // onBlur prop must be after {...register()}
  const onClickNotActive = () => {
    dispatch(nodeNotActiveUpdate())
  }

  const onClickIn = (event) => {
    const payload = { nId }
    dispatch(rowMoveIn(payload))
  }

  const onClickOut = (event) => {
    const payload = { nId }
    dispatch(rowMoveOut(payload))
  }

  const onClickUp = (event) => {
    const payload = { nId, isMoveUp: true }
    dispatch(rowMoveUpDown(payload))
  }

  const onClickDown = (event) => {
    const payload = { nId, isMoveUp: false }
    dispatch(rowMoveUpDown(payload))
  }

  // ----------------------------------------------------------------
  const onClickPlus = () => {
    const payload = { nIdBefore: nId }
    dispatch(rowCreate(payload))
  }

  const onClickMinus = () => {
    const payload = { nId }
    dispatch(rowDelete(payload))
  }

  //=================================================================
  return (
    <Flex>
      <Flex
        h="24px"
        justify="flex-start"
        mb={2}
        bg="pink.50"
        borderRadius="5px 0px 0px 5px"
        border="1px solid #1565c0"
      >
        <Spacer minW={1} maxW={1} />
        <Flex justify="space-between" align="flex-start" mt="-2px">
          {nId !== ROOT && !isLocked ? (
            <chakra.button
              type="button"
              outline="none"
              onClick={onClickIn}
              px={1}
            >
              <BiRightArrowAlt />
            </chakra.button>
          ) : (
            <chakra.button
              type="button"
              outline="none"
              visibility="hidden"
              px={1}
            >
              <BiRightArrowAlt />
            </chakra.button>
          )}
          {!isLocked && isMovedIn ? (
            <chakra.button
              type="button"
              outline="none"
              onClick={onClickOut}
              px={1}
            >
              <BiLeftArrowAlt />
            </chakra.button>
          ) : (
            <chakra.button
              type="button"
              outline="none"
              visibility="hidden"
              px={1}
            >
              <BiLeftArrowAlt />
            </chakra.button>
          )}

          {!isLocked && !isBottom ? (
            <chakra.button
              type="button"
              outline="none"
              onClick={onClickDown}
              px={1}
            >
              <BiDownArrowAlt />
            </chakra.button>
          ) : (
            <chakra.button
              type="button"
              outline="none"
              visibility="hidden"
              px={1}
            >
              <BiDownArrowAlt />
            </chakra.button>
          )}
          {!isLocked && !isTop ? (
            <chakra.button
              type="button"
              outline="none"
              onClick={onClickUp}
              px={1}
            >
              <BiUpArrowAlt />
            </chakra.button>
          ) : (
            <chakra.button
              type="button"
              outline="none"
              visibility="hidden"
              px={1}
            >
              <BiUpArrowAlt />
            </chakra.button>
          )}
        </Flex>

        <Spacer minW={4} maxW={4} />

        <Flex justify="space-between" px={1} align="flex-start" mt="-2px">
          {nId !== ROOT && !isLocked ? (
            <chakra.button
              type="button"
              outline="none"
              onClick={onClickMinus}
              px={1}
            >
              <BiMinus />
            </chakra.button>
          ) : (
            <Box w="16px"></Box>
          )}
          {!isLocked && (
            <chakra.button
              type="button"
              outline="none"
              onClick={onClickPlus}
              px={1}
            >
              <BiPlus />
            </chakra.button>
          )}
        </Flex>
        <Spacer minW={1} maxW={1} />
      </Flex>
      <Flex
        justify="space-between"
        align="flex-start"
        color="white"
        bg="blue.800"
        h="24px"
        borderRadius="0px 5px 5px 0px"
      >
        <chakra.button
          type="button"
          outline="none"
          onClick={onClickNotActive}
          px={1}
          mt="-1px"
        >
          <MdClose />
        </chakra.button>
      </Flex>
    </Flex>
  )
}

export default Component
