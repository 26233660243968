import React from "react"
import { connect } from "react-redux"

import {
  Flex,
  Box,
  Switch,
  FormControl,
  FormLabel,
  Text,
} from "@chakra-ui/react"

import { isNameFirstPreferredUpdate } from "../../redux/actions/project"

const SwitchFirstName = ({ params, isResetForm = false, dispatch }) => {
  //=================================================================

  const { isNameFirstPreferred } = params

  //-----------------------------------------------------------------

  const onChangeNamePreference = (e) => {
    dispatch(isNameFirstPreferredUpdate())
  }

  //=================================================================
  return (
    <Flex justify="space-between" mb={4}>
      <Text>
        Alphabetically ordered by{" "}
        {isNameFirstPreferred ? "first name" : "last name"}.
      </Text>
      <Box>
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="isNameFirstPreferred" mb="0">
            Prefer first name
          </FormLabel>
          <Switch
            id="isNameFirstPreferred"
            onChange={onChangeNamePreference}
            isChecked={isNameFirstPreferred}
          />
        </FormControl>
      </Box>
    </Flex>
  )
}

const mapStateToProps = (state) => {
  return {
    params: state.project.params,
  }
}

export default connect(mapStateToProps)(SwitchFirstName)
