import { extendTheme } from "@chakra-ui/react"

import breakpoints from "./breakpoints"
import colors from "./colors"
import fonts from "./fonts"
import styles from "./styles"
import textStyles from "./textStyles"
import Button from "./Button"
import Container from "./Container"
import Divider from "./Divider"
import Input from "./Input"
import Link from "./Link"
import Text from "./Text"
import Heading from "./Heading"
import Select from "./Select"
import Switch from "./Switch"
import Tooltip from "./Tooltip"

const theme = extendTheme({
  breakpoints,
  colors,
  fonts,
  textStyles,
  styles,
  components: {
    Button,
    Container,
    Divider,
    Input,
    Link,
    Switch,
    Select,
    Heading,
    Text,
    Tooltip,
  },
})

export default theme
