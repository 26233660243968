import produce from "immer"

import {
  accordionStateUpdate,
  isTodayShownUpdate,
  ganttSizeUpdate,
  settingsReset,
  ganttDurationUpdate,
} from "../actions/settings"
import { formParamsUpdate } from "../actions/project"
import settingsInitialState from "../../redux/states/settingsInitialState"

const settings = (slice = [], action) => {
  let sliceNext = slice

  if (sliceNext !== undefined && sliceNext.isFactoryReset !== undefined) {
    sliceNext = produce(sliceNext, (sliceDraft) => {
      sliceDraft.isFactoryReset = false
    })
  }

  const { payload } = action

  switch (action.type) {
    case settingsReset.type:
      sliceNext = { ...settingsInitialState.settings }
      return sliceNext

    case ganttSizeUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.boxW = sliceDraft.boxW + payload.deltaBoxW
        if (sliceDraft.boxW < 1) {
          sliceDraft.boxW = 1
        }
      })
      return sliceNext

    case isTodayShownUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.isTodayShown = !sliceDraft.isTodayShown
      })
      return sliceNext

    case formParamsUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.currencySymbol = payload.data.currencySymbol
      })
      return sliceNext

    case accordionStateUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.accordionState = payload.accordionState
      })
      return sliceNext

    case ganttDurationUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        sliceDraft.ganttDurationIndex =
          ++sliceDraft.ganttDurationIndex %
          sliceDraft.ganttDurationOptions.length
      })
      return sliceNext

    default:
      return sliceNext
  }
}

export default settings
