import React from "react"
import { connect } from "react-redux"
import { Button, Flex, Box, Text } from "@chakra-ui/react"
import { RiDownloadFill } from "react-icons/ri"
import { shortHashGen } from "../../functions/utils/hashes"
import { META_APP_NAME } from "../../const/meta"
import { META_URL_SITE } from "../../const/urlCRA"
import { VERSION_APP, VERSION_API } from "../../const/versions"
import format from "date-fns/format"
import FileSaver from "file-saver"

const ParamsDownload = ({ project }) => {
  //=================================================================

  const jsonDownload = () => {
    const projectTitle = project.statuses[project.statusCurrent].setup.title
    const fNow = format(new Date(), "yyyy-MM-dd_HH-mm")
    const filename =
      fNow +
      "_" +
      projectTitle.replace(/\s+/g, "-").toLowerCase() +
      "_project-status_v" +
      VERSION_APP +
      ".json"

    const jsonObject = {
      appName: META_APP_NAME,
      appURL: META_URL_SITE,
      versionAPP: VERSION_APP,
      versionAPI: VERSION_API,
      projectTitle,
      time: fNow,
      paramsHash: shortHashGen(JSON.stringify({ project })),
      project,
    }

    const jsonContent = JSON.stringify(jsonObject)

    const bytes = new TextEncoder().encode(jsonContent)

    const blob = new Blob([bytes], {
      type: "application/json;charset=utf-8",
    })

    FileSaver.saveAs(blob, filename)

    // const encodedDataUrl = encodeURI(
    //   `data:text/csv;charset=utf8,${jsonContent}`
    // )
    // const link = document.createElement("a")
    // link.setAttribute("href", encodedDataUrl)
    // link.setAttribute("download", "2" + filename)
    // link.click()
    // link.remove()
  }

  //=================================================================
  return (
    <Button onClick={() => jsonDownload()} mx={1}>
      <Flex>
        <Text>DOWNLOAD</Text>
        <Box position="relative" top="-4px" left="4px">
          <RiDownloadFill />
        </Box>
      </Flex>
    </Button>
  )
}

const mapStateToProps = (state) => {
  return {
    project: state.project,
  }
}

export default connect(mapStateToProps)(ParamsDownload)
