import { Flex, Box, Spacer, Tooltip } from "@chakra-ui/react"
import * as WIDTH from "../../const/widths"

import { BiBlock } from "react-icons/bi"

const SheetHeader = ({ params }) => {
  //=================================================================
  const { isSlimOn } = params
  const isSlimBin = isSlimOn ? 1 : 0
  //=================================================================
  return (
    <Flex mb={4} mr={2}>
      <Tooltip
        label="DEFAULT → UNRESOLVED → IGNORED → DEFAULT. UNRESOLVED: Mark unresolved issues. The number of unresolved issues will be shown under the Important Ratios. IGNORED: Click to ignore issues. The time spent will still be counted in the accumulation, but traffic light colors will not be considered in the aggregation."
        placement="top-start"
        hasArrow
      >
        <Flex
          w={WIDTH.MARK[isSlimBin]}
          px={0}
          color="white"
          bg="blue.400"
          justify="space-around"
        >
          <Box position="relative" top="-2px">
            <BiBlock />
          </Box>
        </Flex>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="POSITION: Shows the hierarchy of the deliverable."
        placement="top-start"
        hasArrow
      >
        <Box w={WIDTH.POSITION[isSlimBin]} color="white" bg="blue.400" px={1}>
          POSITION
        </Box>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="WHO: The sole responsible person for the deliverable. Enter a new person or select an existing one. Remove with the little ×."
        placement="top"
        hasArrow
      >
        <Box w={WIDTH.WHO[isSlimBin]} bg="blue.400" color="white" px={1}>
          WHO
        </Box>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="DELIVERS WHAT: The deliverable that is the resulting output."
        placement="top"
        hasArrow
      >
        <Box w={WIDTH.WHAT[isSlimBin]} bg="blue.400" color="white" px={1}>
          DELIVERS WHAT
        </Box>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="BY WHEN: The promised delivery date. A down pointing arrow will fixate the due date, a right pointing arrow will allow for a recalculation based on duration. "
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.WHEN[isSlimBin]}
          bg="blue.400"
          color="white"
          px={1}
          textAlign="right"
        >
          BY WHEN
        </Box>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="TO WHOM: Person who receives the deliverable and then has to evaluate its quality."
        placement="top"
        hasArrow
      >
        <Box w={WIDTH.WHO[isSlimBin]} bg="blue.400" color="white" px={1}>
          TO WHOM
        </Box>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="SPENT: The time that has already been spent working on the deliverable."
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.SPENT[isSlimBin]}
          bg="blue.400"
          color="white"
          px={1}
          textAlign="right"
        >
          SPENT
        </Box>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="DEGREE OF COMPLETION (%): A reasonable percentage number that reflects the degree of completion of the deliverable."
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.DEGREE[isSlimBin]}
          bg="blue.400"
          color="white"
          px={1}
          textAlign="right"
        >
          %
        </Box>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="DL — DEADLINE: A traffic light color evaluating if the deliverable has been delivered on time before the deadline to 100%. The traffic light color is set automatically."
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.DEADLINE[isSlimBin]}
          color="white"
          bg="blue.400"
          textAlign="center"
        >
          DL
        </Box>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="FC — FORECAST: A forecast if the promised delivery date will be met."
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.FORECAST[isSlimBin]}
          color="white"
          bg="blue.400"
          textAlign="center"
        >
          FC
        </Box>
      </Tooltip>

      <Spacer bg="transparent" />

      <Tooltip
        label="Q — QUALITY: A traffic light color with which the receiver (TO WHOM) evaluates the quality of the deliverable. A red quality requires the setup of a new entry for improving the quality to green."
        placement="top"
        hasArrow
      >
        <Box
          w={WIDTH.QUALITY[isSlimBin]}
          bg="blue.400"
          color="white"
          px={0}
          textAlign="center"
        >
          Q
        </Box>
      </Tooltip>

      {!isSlimOn && <Spacer bg="transparent" />}

      {!isSlimOn && (
        <Tooltip
          label="COMMENT: Every traffic light color other than green requires a comment."
          placement="top"
          hasArrow
        >
          <Box w={WIDTH.COMMENT[isSlimBin]} bg="blue.400" color="white" px={1}>
            COMMENT (ISSUE or POTENTIAL FIX)
          </Box>
        </Tooltip>
      )}

      {!isSlimOn && <Spacer bg="transparent" />}

      {!isSlimOn && (
        <Tooltip
          label="PRECEDENTS: Enter positions that need to be finished before the work on this deliverable can start. Separate with commas."
          placement="top-end"
          hasArrow
        >
          <Box
            w={WIDTH.PRECS[isSlimBin]}
            color="white"
            bg="blue.400"
            px={1}
            textAlign="center"
          >
            PRECS
          </Box>
        </Tooltip>
      )}
    </Flex>
  )
}

export default SheetHeader
