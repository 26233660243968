import cloneDeep from "lodash.clonedeep"
import strCapitalize from "../utils/strCapitalize"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Generates names from an overall personName
 * @param {string} personName
 * @param {boolean} isNameFirstPreferred
 * @returns {object} names
 */
export const namesGen = (personName, isNameFirstPreferred) => {
  let names = {
    nameFirst: "",
    nameLast: "",
    nameDisplayShort: "",
    nameDisplayLong: "",
    nameInitials: "",
  }
  personName = strCapitalize(personName)

  if (isNameFirstPreferred) {
    // there is a space in the name
    if (personName.indexOf(" ") > -1) {
      names.nameFirst = personName.split(" ")[0]
      names.nameLast = strCapitalize(personName.split(" ")[1])
      names.nameDisplayShort = names.nameFirst + " " + names.nameLast[0]
      names.nameDisplayLong = names.nameFirst + " " + names.nameLast
      names.nameInitials = names.nameFirst[0] + names.nameLast[0]
    } else {
      names.nameFirst = personName
      names.nameLast = ""
      names.nameDisplayShort = personName
      names.nameDisplayLong = personName
      names.nameInitials = personName[0]
    }
  } else {
    if (names.personName.indexOf(" ") > -1) {
      names.nameLast = personName.split(" ")[0]
      names.nameFirst = strCapitalize(personName.split(" ")[1])
      names.nameDisplayShort = names.nameLast + " " + names.nameFirst[0]
      names.nameDisplayLong = names.nameFirst + " " + names.nameLast
      names.nameInitials = names.nameFirst[0] + names.nameLast[0]
    } else {
      names.nameLast = personName
      names.nameFirst = ""
      names.nameDisplayShort = personName
      names.nameDisplayLong = personName
      names.nameInitials = personName[0]
    }
  }

  return names
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Generates a displayNameShort from firstName and LastName
 * @param {string} nameFirst
 * @param {string} nameLast
 * @param {boolean} isNameFirstPreferred
 * @return {string} isNameFirstPreferred
 */
export function nameDisplayShortGen(
  nameFirst,
  nameLast,
  isNameFirstPreferred = "true"
) {
  if (nameFirst === undefined || nameFirst === null) {
    nameFirst = ""
  }
  if (nameLast === undefined || nameFirst === null) {
    nameLast = ""
  }
  let nameDisplayShort = ""

  if (nameFirst === "" && nameLast === "") {
    nameDisplayShort = ""
  } else if (nameFirst === "" || nameLast === "") {
    nameDisplayShort = nameFirst + nameLast
  } else if (isNameFirstPreferred) {
    nameDisplayShort = nameFirst + " " + nameLast[0]
  } else {
    nameDisplayShort = nameLast + " " + nameFirst[0]
  }

  return nameDisplayShort
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Generates a displayNameLong from firstName and LastName
 * @param {string} nameFirst
 * @param {string} nameLast
 * @param {boolean} isNameFirstPreferred
 * @return {string} isNameFirstPreferred
 */
export function nameDisplayLongGen(nameFirst, nameLast, academicTitle = "") {
  let nameDisplayLong = ""

  nameFirst.trim()
  nameLast.trim()

  if (nameFirst === "" && nameLast === "") {
    nameDisplayLong = ""
  } else if (nameFirst === "" || nameLast === "") {
    nameDisplayLong = academicTitle + " " + nameFirst + nameLast
  } else {
    nameDisplayLong = academicTitle + " " + nameFirst + " " + nameLast
  }

  nameDisplayLong = nameDisplayLong.trim()

  return nameDisplayLong
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Generate the displayName of a person object from existing names
 * and return the SAME object
 * @param {object} persons - object of persons
 * @param {boolean} isNameFirstPreferred
 * @return {object} person
 */
export function personDisplayNameGen(person, isNameFirstPreferred) {
  if (person.nameFirst === "" && person.nameLast === "") {
    person.nameDisplayShort = ""
    person.nameDisplayLong = ""
  } else if (person.nameFirst === "" || person.nameLast === "") {
    person.nameDisplayShort = person.nameFirst + person.nameLast
    person.nameDisplayLong = person.nameFirst + person.nameLast
  } else if (isNameFirstPreferred) {
    person.nameDisplayShort = person.nameFirst + " " + person.nameLast[0]
    person.nameDisplayLong = person.nameFirst + " " + person.nameLast
  } else {
    person.nameDisplayShort = person.nameLast + " " + person.nameFirst[0]
    person.nameDisplayLong = person.nameLast + " " + person.nameFirst
  }

  return person
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Generate the displayName for all persons in the persons object.
 * It returns a NEW object.
 * @param {object} persons - object of persons
 * @param {boolean} isNameFirstPreferred
 * @return {object} personsNew
 */
export function personsDisplayNameGen(persons, isNameFirstPreferred) {
  let personsNew = cloneDeep(persons)

  Object.keys(personsNew).forEach((sId) => {
    personDisplayNameGen(personsNew[sId], isNameFirstPreferred)
  })
  return personsNew
}
