import React from "react"
import { connect } from "react-redux"
import CreatableSelect from "react-select/creatable"
import CustomClearIndicator from "./CustomClearIndicator"
import customStyles from "./style/customStyles"
import { SelectRequestsUpdate } from "../../redux/actions/project"

const SelectRequests = ({
  name,
  value,
  optionsRequests,
  isDisabled = false,
  isLocked = false,
  dispatch,
}) => {
  //=================================================================
  const onChange = (data, actionMeta) => {
    // data.label has either the sId or - if new - the new name
    // select Id = sId has nId here

    let payload = {}

    if (actionMeta.action === "clear") {
      payload = { name, selected: [], isNew: false }
    } else {
      payload = { name, selected: data }
    }

    dispatch(SelectRequestsUpdate(payload))
  }
  //=================================================================
  return (
    <CreatableSelect
      name="requests"
      value={value}
      onChange={onChange}
      options={optionsRequests}
      styles={customStyles}
      isMulti
      isDisabled={isLocked}
      isLocked={isLocked}
      className="basic-multi-select"
      classNamePrefix="select"
      components={{
        ClearIndicator: CustomClearIndicator,
        DropdownIndicator: null,
        IndicatorSeparator: null,
        Placeholder: () => null,
      }}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    optionsRequests: [
      ...state.project.requestsTypical,
      ...state.project.statuses[state.project.statusCurrent].requestsAdded,
    ],
  }
}

export default connect(mapStateToProps)(SelectRequests)
