const settingsInitialState = {
  settings: {
    accordionState: [4],
    boxW: 12,
    currencySymbol: "T€",
    isTodayShown: true,
    ganttDurationIndex: 0,
    // ganttDurationOptions: ["d", "w", "m"],
    ganttDurationOptions: ["d", "w"],
  },
}

export default settingsInitialState
