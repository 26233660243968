/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * checks if a node can become an aggregation node
 * @param {object} node
 * @returns {object} node
 */
export const nodeCanBecomeAggregationCalc = (node) => {
  return (
    node.children.length === 0 &&
    node.isIgnored === false &&
    node.isUnresolved === false &&
    node.spent.days === 0 &&
    node.spent.hours === 0 &&
    node.degree === 0 &&
    node.deadline === 0 &&
    node.forecast === 0 &&
    node.quality === 0
  )
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * checks if a node has not received user information
 * @param {object} node
 * @returns {object} node
 */
export const nodeIsEmptyCalc = (node) => {
  return (
    node.children.length === 0 &&
    node.precedents.length === 0 &&
    node.isIgnored === false &&
    node.isUnresolved === false &&
    node.who === "" &&
    node.deliversWhat === "" &&
    node.toWhom === "" &&
    node.spent.days === 0 &&
    node.spent.hours === 0 &&
    node.degree === 0 &&
    node.deadline === 0 &&
    node.forecast === 0 &&
    node.quality === 0 &&
    node.comment === ""
  )
}
