import React from "react"
import { Tooltip, Box } from "@chakra-ui/react"

const StatusUpload = ({ isWarning, isError, message = "" }) => {
  const okMsg = "OK: Upload successful."

  if (message === "") {
    message = okMsg
  }

  let color = isError ? "white" : isWarning ? "black" : "white"
  let bg = isError ? "red.500" : isWarning ? "yellow.500" : "green.500"

  return (
    <Tooltip
      label={message}
      placement="right"
      color={color}
      bg={bg}
      isOpen
      hasArrow
    >
      <Box w="1px"></Box>
    </Tooltip>
  )
}

export default StatusUpload
