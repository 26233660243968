import cloneDeep from "lodash.clonedeep"
import { ROOT } from "../../const/globals"

/**
 * v1.0.0: (c) Jasper Anders
 * v1.1.0: (c) Prof. Dr. Ulrich Anders
 *
 * Transforms a position into a nId
 * @param {object} nodes
 * @param {array} position
 * @returns {string} nId
 */
export function positionToNId(nodes, position) {
  let nId = ""
  let node = nodes[ROOT]
  position.forEach((el) => {
    if (node !== undefined) {
      // recursive over position elements
      node = nodes[node.children[el - 1]]
      if (!!node) {
        nId = node.nId
      } else {
        nId = ""
      }
    }
  })
  return nId
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Transforms a precedentsStr string into an array of positions
 * @param {string} precedentsStr
 * @return {array} positions
 */
export function precedentsStrToPositionsArr(precedentsStr) {
  if (precedentsStr.trim() === "") {
    return []
  }

  // "01.03, 01.04"
  let precsPosArr = precedentsStr.split(",").filter((el) => !!el)
  // → ["01.03", "01.04"]

  precsPosArr = precsPosArr.map((posStr) => posStr.split("."))
  // → [["01", "03"], ["02","04"]]

  precsPosArr = precsPosArr.map((posArr) => posArr.map((el) => parseInt(el)))
  // → [[1, 3], [2, 4]]

  return precsPosArr
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Transforms an array of positions into a precedentsStr string
 * @param {array} positionsArr
 * @return  {string} precedentsStr
 */
export function positionsArrToPrecedentsStr(positionsArr) {
  if (positionsArr.length === 0) {
    return ""
  }

  // [[1, 3], [2, 4]]
  let precedentsStr = positionsArr.map((posArr) =>
    posArr.map((el) => el.toString().padStart(2, "0"))
  )
  // → [["01", "03"], ["02", "04"]]

  // [["01", "03"], ["02", "04"]]
  precedentsStr = precedentsStr.map((posStr) => posStr.join("."))
  // → [["01.03"], ["02.04"]]

  // ["01.03", "01.04"]
  precedentsStr = precedentsStr.join(", ")
  // →  "01.03, 01.04"

  return precedentsStr
}

/**
 * v1.0.0: (c) Jasper Anders
 * v1.0.1: (c) Prof. Dr. Anders
 *
 * Transforms a positions array into a nId array, e.g.
 * [[1, 2], [2, 4]] → ["de4tyd", "opl9iu"]
 * @param {object} nodes
 * @param {array} posArr array of position arrays
 * @returns {array} nIdArr
 */
export function posArrToNIdArr(nodes, posArr) {
  const nIdArr = [...posArr.map((position) => positionToNId(nodes, position))]
  const nIdArrFiltered = nIdArr.filter((el) => el !== "")
  return nIdArrFiltered
}

/**
 * v1.0.0: (c) Jasper Anders
 * v1.1.1: (c) Prof. Dr. Ulrich Anders
 *
 * Transform a precedentsStr string into an array of nIds.
 * Does not allow ancestors or descendants to be precedents
 * and filters them out.
 * Example:
 * precsPosArrValidated: [[1, 1], [2, 4, 3, 1], [3]]
 * // → to become nodes[nId].precedents after posArrToNIdArr
 * nodes[nId].position: [1, 1, 2]
 * @param {object} nodes
 * @param {string} precedentsStr
 * @return {array} array of nIds
 */
export function precedentsPosStrToNIdArr(nodes, nId, precedentsStr) {
  let precsPosArr = precedentsStrToPositionsArr(precedentsStr)

  // clean for non existent precedentStr in precedentsStr
  const precsNIdArr = posArrToNIdArr(nodes, precsPosArr)
  precsPosArr = precsNIdArr.map((precsNid) => nodes[precsNid].position)

  // Every position is an array comprised of array elements.
  // It will be checked if the shorter array is exactly in the longer.
  // If yes, it is not valid and will be filtered out.
  const precsPosArrValidated = precsPosArr.filter((positionArr) => {
    const positionLonger =
      nodes[nId].position.length > positionArr.length
        ? nodes[nId].position
        : positionArr

    const positionShorter =
      nodes[nId].position.length <= positionArr.length
        ? nodes[nId].position
        : positionArr

    const isPositionShorterInLonger = positionShorter.every((el, index) => {
      if (el !== positionLonger[index]) {
        return false
      }
      return true
    })

    return !isPositionShorterInLonger
  })

  return posArrToNIdArr(nodes, precsPosArrValidated)
}

/**
 * v1.0.0: (c) Jasper Anders
 * v1.0.2: (c) Prof. Dr. Ulrich Anders
 *
 * Transforms a dependencies array to a positionStr, e.g.
 * ["mu7ikl", "qwe2uv"] → "02.03, 04.02"
 * @param {object} nodes
 * @param {array} depsArr
 * @return {string} posStr
 */
export function depsArrToPosStr(nodes, depsArr) {
  if (depsArr.length === 0) {
    return ""
  }
  let posStr = depsArr
    .map((el) => {
      if (!nodes[el]) {
        return ""
      }
      return nodes[el].position
        .map((pos) => pos.toString().padStart(2, "0"))
        .join(".")
    })
    .join(", ")

  return posStr
}

/**
 * v1.0.0: (c) Jasper Anders
 * v1.2.0: (c) Prof. Dr. Ulrich Anders
 *
 * Sets the precedents of node nId but filters out any ancestors.
 * Adds nId to the dependents of each precedent
 * @param {object} nodes
 * @param {string} nId
 * @param {array} precedentsStr a dep. string as it comes from the form
 */
export function nodesDependenciesSet(nodes, nId, precedentsStr) {
  const nodesNew = cloneDeep(nodes)

  // remove nId from dependents to clean up state
  // before new precedentsStr is processed
  nodesNew[nId].precedents.forEach((precedent) => {
    nodesNew[precedent].dependents = nodesNew[precedent].dependents.filter(
      (dependent) => dependent !== nId
    )
  })

  // ["01.02", "02.04", "42.42"]
  const precedentsNIdArr = precedentsPosStrToNIdArr(
    nodesNew,
    nId,
    precedentsStr
  )
  // → ["d4rio2", "eu8ihg"]

  nodesNew[nId].precedents = precedentsNIdArr

  // for validation
  nodesNew[nId].precedentsStr = depsArrToPosStr(
    nodesNew,
    nodesNew[nId].precedents
  )

  // set dependents
  precedentsNIdArr.forEach((precedentNId) => {
    nodesNew[precedentNId].dependents.push(nId)
  })

  return nodesNew
}
