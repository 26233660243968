import addBusinessDays from "date-fns/addBusinessDays"
import addHours from "date-fns/addHours"
import differenceInBusinessDays from "date-fns/differenceInBusinessDays"
import getHours from "date-fns/getHours"
import isBefore from "date-fns/isBefore"
import isWeekend from "date-fns/isWeekend"
import max from "date-fns/max"
import min from "date-fns/min"
import parseISO from "date-fns/parseISO"
import setHours from "date-fns/setHours"
import startOfDay from "date-fns/startOfDay"

import cloneDeep from "lodash.clonedeep"

import { dateDurationAdd } from "../../functions/timeHandler/dateDurationAdd"
import { durToHours, hoursToDur } from "../timeHandler/durations"

import { DAY_END, DAY_START, ROOT, HOURS_PER_DAY } from "../../const/globals"

// import { deepDiff } from "../utils/deepDiff"

// HELPERS =================================================================

/**
 * (c) Jasper Anders
 * (c) Prof. Dr. Ulrich Anders
 *
 * Adds one hour to a date, respecting DAY_START and DAY_END
 * @param {string} isoDate
 * @returns {date} dateAdded
 */
export function isoDateOneHourAdd(
  isoDate,
  businessDayStart = DAY_START,
  businessDayEnd = DAY_END
) {
  let dateAdded = addHours(parseISO(isoDate), 1)
  if (getHours(dateAdded) >= businessDayEnd || isWeekend(dateAdded)) {
    dateAdded = addBusinessDays(dateAdded, 1)
    dateAdded = startOfDay(dateAdded)
    dateAdded = setHours(dateAdded, businessDayStart)
  }
  return dateAdded
}

/**
 * (c) Jasper Anders
 * TEST: ok
 *
 * Returns the sum of durations that are in an array
 * @param {array of strings} durationsArray
 * @return {int} hoursPerDay
 * @return {int} sum
 */
export function durationsArraySumCalc(
  durationsArray,
  hoursPerDay = HOURS_PER_DAY
) {
  let sum = { days: 0, hours: 0 }
  durationsArray.forEach((element) => {
    sum.hours += element.hours
    sum.days += element.days + Math.floor(sum.hours / hoursPerDay)
    sum.hours = sum.hours % hoursPerDay
  })
  return sum
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Returns the minimum int from an array keeping only numbers > 0
 * @param {*} arr
 * @return {int} min
 */
export const arrFilteredMinGet = (arr) => {
  const arrFiltered = arr.filter((num) => num > 0)
  let min = 0
  if (arrFiltered.length > 0) {
    min = Math.min(...arrFiltered)
  }
  return min
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Returns the minimum of two isoDates strings.
 * An isoDate === "" is eternity.
 * @param {string} isoDate1
 * @param {string} isoDate2
 * @returns {boolean} isBefore
 */
export function isBeforeISO(isoDate1, isoDate2) {
  if (isoDate1 === "" && isoDate2 === "") {
    return false
  } else if (isoDate2 === "") {
    return true
  } else if (isoDate1 === "") {
    return false
  }

  const date1 = parseISO(isoDate1)
  const date2 = parseISO(isoDate2)

  const temp = isBefore(date1, date2)

  // console.log(isoDate1, isoDate2, temp)

  return temp
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Returns the minimum of two isoDates strings
 * @param {string} isoDate1
 * @param {string} isoDate2
 * @returns {string} minIsoDate
 */
export function minISO(isoDate1, isoDate2) {
  let minIsoDate = ""
  if (isoDate1 === "" && isoDate2 === "") {
    return minIsoDate
  } else if (isoDate1 === "" || isoDate2 === "") {
    return isoDate1 + isoDate2
  }

  const date1 = parseISO(isoDate1)
  const date2 = parseISO(isoDate2)

  minIsoDate = min([date1, date2]).toISOString()

  return minIsoDate
}

/**
 * (c) Prof. Dr. Ulrich Anders
 * STATUS: NOT NEEDED
 *
 * Returns the maximum of two isoDates strings
 * @param {string} isoDate1
 * @param {string} isoDate2
 * @returns {string} maxIsoDate
 */
export function maxISO(isoDate1, isoDate2) {
  let maxIsoDate = ""
  if (isoDate1 === "" && isoDate2 === "") {
    return maxIsoDate
  } else if (isoDate1 === "" || isoDate2 === "") {
    return isoDate1 + isoDate2
  }

  const date1 = parseISO(isoDate1)
  const date2 = parseISO(isoDate2)

  maxIsoDate = max([date1, date2]).toISOString()

  return maxIsoDate
}

/**
 * v1.0.0: (c) Jasper Anders
 * v1.1.0: (c) Prof. Dr. Ulrich Anders
 *
 * Returns a projection duration object based on the
 * linear extrapolation of spent given degree
 * @param {object} spent
 * @param {int} degree
 * @return {object} projection
 */
export function projectionFromSpentExtrapolate(spent, degree) {
  const spentHours = durToHours(spent)
  let projection = { hours: 0, days: 0 }
  if (degree === 100) {
    projection = cloneDeep(spent)
  } else if (degree > 0) {
    projection = hoursToDur(Math.ceil((spentHours / degree) * 100))
  }

  return projection
}

// GOING DOWN & INHERIT STATE ====================================================

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Cleans and mutates the nodes[nId].precedents, nodes[pId].dependents,
 * and nodes[nId].precedents[precedent].dependents
 * Removes:
 * - precedent that is the parent
 * - precedents that are already precedents in the parent
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes
 */
export function nodeDependenciesClean(nodes, nId) {
  const { pId } = nodes[nId]

  // if parent is a precedent
  if (
    nodes[nId].precedents.length > 0 &&
    nodes[nId].precedents.indexOf(pId) > -1
  ) {
    // filter out pId in node[nId].precedents
    nodes[nId].precedents = nodes[nId].precedents.filter(
      (precedent) => precedent !== pId
    )
    // filter out nId in node[pId].dependents
    nodes[pId].dependents = nodes[pId].dependents.filter(
      (dependent) => dependent !== nId
    )
  }

  //

  if (nodes[nId].precedents.length > 0 && nodes[pId].precedents.length > 0) {
    // keep precedent only if not found in precedent of parent
    nodes[nId].precedents.forEach((precedent) => {
      if (nodes[pId].precedents.indexOf(precedent) >= -1) {
        nodes[precedent].dependents = nodes[precedent].dependents.filter(
          (dependent) => dependent !== nId
        )
      }
    })
    nodes[nId].precedents = nodes[nId].precedents.filter(
      (precedent) => nodes[pId].precedents.indexOf(precedent) === -1
    )
  }

  return nodes[nId]
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Calculates and mutates fromWhenEarliest of node nId
 * inherited from fromWhenEarliest from the parent and
 * dependent on the byWhen of the precedents.
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes
 */
export function nodeFromWhenEarliestSet(nodes, nId) {
  const { precedents } = nodes[nId]
  const dates = []

  // inherited from parents
  if (nodes[nId].fromWhenEarliest !== "") {
    dates.push(parseISO(nodes[nId].fromWhenEarliest))
  }

  // received from precedents
  if (precedents.length > 0) {
    precedents.forEach((precedent) => {
      // console.log("nodeFromWhenEarliestSet: ", { ...nodes[precedent] })
      dates.push(isoDateOneHourAdd(nodes[precedent].byWhen))
    })
  }

  if (dates.length > 0) {
    nodes[nId].fromWhenEarliest = max(dates).toISOString()
    // console.log("nodeFromWhenEarliestSet: ", {
    //   nId,
    //   fromWhenEarliest: nodes[nId].fromWhenEarliest,
    // })
  }

  // console.log("nodeFromWhenEarliestSet", nId, nodes[nId].fromWhenEarliest)

  return nodes[nId]
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Recursively pulls byWhenLatest from dependents if it is smaller.
 * Helper function for nodeByWhenLatestSet()
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes[nId]
 */
const nodeByWhenLatestPull = (nodes, nId) => {
  const { dependents } = nodes[nId]

  // console.log("nodeByWhenLatestPull", {
  //   nId,
  //   dependents: JSON.stringify(dependents),
  // })

  dependents.forEach((dependent) => {
    // console.log("nodeByWhenLatestPull for Each", { nId, dependent })
    nodes[dependent] = nodeByWhenLatestPull(nodes, dependent)
    if (isBeforeISO(nodes[dependent].byWhenLatest, nodes[nId].byWhenLatest)) {
      nodes[nId].byWhenLatest = nodes[dependent].byWhenLatest

      // console.log("nodeByWhenLatestPull: ", { nId, dependent, msg: "pulled" })
    }
  })

  return nodes[nId]
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Recursively pushes byWhenLatest to all precedent if it is smaller.
 * Helper function for nodeByWhenLatestSet()
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes[nId]
 */
const nodeByWhenLatestPush = (nodes, nId) => {
  const { precedents } = nodes[nId]

  // console.log("nodeByWhenLatestPush ", {
  //   nId,
  //   precedents: JSON.stringify(precedents),
  // })

  precedents.forEach((precedent) => {
    // console.log("nodeByWhenLatestPush forEach", { nId, precedent })
    if (isBeforeISO(nodes[nId].byWhenLatest, nodes[precedent].byWhenLatest)) {
      nodes[precedent].byWhenLatest = nodes[nId].byWhenLatest

      // console.log("nodeByWhenLatestPush: ", { precedent, nId, msg: "pushed" })
    }
    nodes[precedent] = nodeByWhenLatestPush(nodes, precedent)
  })

  return nodes[nId]
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Calculates and mutates byWhenLatest of node nId
 * inherited from fromWhenEarliest from the parent and
 * dependent on the byWhen of the dependents.
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes
 */
export function nodeByWhenLatestSet(nodes, nId) {
  // pull recursively byWhenLatest from all dependents
  nodes[nId] = nodeByWhenLatestPull(nodes, nId)
  // push byWhenLatest recursively to all precedents
  nodes[nId] = nodeByWhenLatestPush(nodes, nId)

  return nodes[nId]
}

// SET STATE ===============================================================

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Mutates fromWhen and byWhen of node nId and
 * re-calculates node.span if necessary.
 * Set fromWhen to:
 *   - fromWhenEarliest (inherited from the parent or because of precedents)
 * Set byWhen depending on:
 *   - byWhenLatest inherited from the parent
 *   - byWhenPinned from the node itself
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodesNew
 */
export function nodeFromByWhenSet(node) {
  const fromWhenPrevious = node.fromWhen
  const byWhenPrevious = node.byWhen
  const datesByWhen = []

  if (node.fromWhenEarliest !== "") {
    node.fromWhen = node.fromWhenEarliest
  }

  // byWhen ---
  if (node.isByWhenPinned) {
    node.byWhenLatest = minISO(node.byWhen, node.byWhenLatest)

    // console.log("nodeFromByWhenSet: ", {
    //   nId: node.nId,
    //   byWhenLatest: node.byWhenLatest,
    // })
  }
  // TODO: RESET?
  // else {}

  if (node.byWhenLatest !== "") {
    datesByWhen.push(parseISO(node.byWhenLatest))
  }

  datesByWhen.push(dateDurationAdd(parseISO(node.fromWhen), node.span))

  // console.log("nodeFromByWhenSet: ", { datesByWhen, node })

  node.byWhen = min(datesByWhen).toISOString()

  if (node.fromWhen !== fromWhenPrevious || node.byWhen !== byWhenPrevious) {
    node = nodeSpanSet(node)
  }

  return node
}

/**
 * (c) Ulrich Anders
 *
 * Mutates the node and sets the span based on
 * byWhen and fromWhen.
 * It returns the node itself.
 * @param {object} node
 * @param {string} nId
 * @param {object} node
 */
export function nodeSpanSet(
  node,
  businessDayStart = DAY_START,
  businessDayEnd = DAY_END
) {
  const { byWhen, fromWhen } = node
  const byWhenDate = parseISO(byWhen)
  const fromWhenDate = parseISO(fromWhen)

  let businessDays = differenceInBusinessDays(byWhenDate, fromWhenDate)

  const hoursDiff = getHours(byWhenDate) - getHours(fromWhenDate)
  let hours = hoursDiff

  if (getHours(fromWhenDate) + hoursDiff > businessDayEnd) {
    const hoursRest = hours - businessDayEnd
    businessDays += 1
    hours = hoursRest
  } else if (getHours(fromWhenDate) + hoursDiff <= businessDayStart) {
    const hoursRest = businessDayEnd - (businessDayStart - hours)
    businessDays -= 1
    hours = hoursRest
  }

  node.span = {
    days: businessDays ? businessDays : 0,
    hours: hours ? hours : 0,
  }

  return node
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Mutates the node and sets projection and slack value.
 * Note that span and spent should already be set.
 * @param {*} node
 * @returns {object} node
 */
export const nodeProjectionSlackSet = (node) => {
  node.projection = projectionFromSpentExtrapolate(node.spent, node.degree)
  // initially the projection is set to the span
  if (node.projection.days === 0 && node.projection.hours === 0) {
    node.projection = cloneDeep(node.span)
  }
  if (node.isIgnored) {
    node.projection = cloneDeep(node.spent)
  }

  const hoursSpan = durToHours(node.span)
  const hoursProjection = durToHours(node.projection)
  node.slack = hoursToDur(hoursSpan - hoursProjection)

  return node
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Mutates the node and sets the Deadline Traffic Light
 * and sets forecast and quality Traffic Lights accordingly
 * @param {*} node
 * @param {*} dId
 * @returns
 */
export const nodeTrafficLightsSet = (node, dId) => {
  const { byWhen, degree } = node

  const isByWhenBeforeStatus = isBefore(parseISO(byWhen), parseISO(dId))

  if (degree >= 100) {
    node.deadline = 4 // green
    node.forecast = 0
  }
  // < 100%
  else {
    if (isByWhenBeforeStatus) {
      node.deadline = 1 // red
      node.forecast = 0
      node.quality = 0
    } else {
      node.deadline = 0
      node.quality = 0
    }
  }
  return node
}

// AGGregat STATE ===============================================================

/**
 * (c) Prof. Dr. Ulrich Anders
 * TODO: no isPinned
 *
 * Mutates the nodes[nId] and aggregates byWhen and fromWhen.
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes[nId]
 */
export function nodeFromByWhenAgg(nodes, nId) {
  const children = nodes[nId].children
  const childrenFiltered = children.filter(
    (child) => !nodes[child].isUnresolved && !nodes[child].isIgnored
  )

  if (childrenFiltered.length > 0) {
    let fromWhenDates = childrenFiltered.map((child) =>
      parseISO(nodes[child].fromWhen)
    )
    let byWhenDates = childrenFiltered.map((child) =>
      parseISO(nodes[child].byWhen)
    )

    nodes[nId].fromWhen = min(fromWhenDates).toISOString()
    nodes[nId].byWhen = max(byWhenDates).toISOString()
  }

  return nodes[nId]
}

/**
 * (c) Prof. Dr. Ulrich Anders
 * TODO: no isPinned
 *
 * Mutates the nodes[nId] and aggregates projection and spent.
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes[nId]
 */
export function nodeProjectionSpentDegreeAgg(nodes, nId) {
  // defaults that may be overrode
  nodes[nId].spent = { days: 0, hours: 0 }
  nodes[nId].projection = { days: 0, hours: 0 }
  nodes[nId].degree = 0

  const children = nodes[nId].children

  // spent values are aggregated even if the deliverable
  // is isIgnored or if the degree is 0
  const childrenForSpent = children.filter(
    (child) => !nodes[child].isUnresolved
  )

  if (childrenForSpent.length > 0) {
    const spents = childrenForSpent.map((child) => nodes[child].spent)
    nodes[nId].spent = durationsArraySumCalc(spents)
  }

  // projections are ony aggregated for deliverables that are
  // not isUnresolved and not isIgnored
  const childrenForProjection = children.filter(
    (child) => !nodes[child].isUnresolved && !nodes[child].isIgnored
  )

  if (childrenForProjection.length > 0) {
    const projections = childrenForProjection.map(
      (child) => nodes[child].projection
    )
    nodes[nId].projection = durationsArraySumCalc(projections)
  }

  // degrees are only aggregated for deliverables that are
  // not isUnresolved, not isIgnored and have a degree > 0
  const childrenForDegree = children.filter(
    (child) =>
      !nodes[child].isUnresolved &&
      !nodes[child].isIgnored &&
      nodes[child].degree > 0
  )

  if (childrenForDegree.length > 0) {
    const spentsForDegree = childrenForDegree.map((child) => nodes[child].spent)
    const spentForDegree = durationsArraySumCalc(spentsForDegree)

    const projectionsForDegree = childrenForDegree.map(
      (child) => nodes[child].projection
    )
    const projectionForDegree = durationsArraySumCalc(projectionsForDegree)

    const isSomeChildrenDegree = childrenForDegree.some(
      (child) => nodes[child].degree > 0
    )

    const hoursProjection = durToHours(projectionForDegree)
    if (hoursProjection > 0 && isSomeChildrenDegree > 0) {
      const hoursSpent = durToHours(spentForDegree)
      nodes[nId].degree = Math.round((hoursSpent / hoursProjection) * 100)
    }
  }

  return nodes[nId]
}

/**
 * (c) Prof. Dr. Ulrich Anders
 * TODO: no isPinned
 *
 * Mutates the nodes[nId] and aggregates projection and spent.
 * @param {object} nodes
 * @param {string} nId
 * @returns {object} nodes[nId]
 */
export function nodeTrafficLightsAgg(nodes, nId) {
  // default values may be overrode
  nodes[nId].deadline = 0
  nodes[nId].forecast = 0
  nodes[nId].quality = 0

  const children = nodes[nId].children

  const childrenFiltered = children.filter(
    (child) => !nodes[child].isUnresolved && !nodes[child].isIgnored
  )

  if (childrenFiltered.length > 0) {
    const deadlines = childrenFiltered.map((child) => nodes[child].deadline)
    const forecasts = childrenFiltered.map((child) => nodes[child].forecast)
    const qualities = childrenFiltered.map((child) => nodes[child].quality)

    nodes[nId].deadline = arrFilteredMinGet(deadlines)
    nodes[nId].forecast = arrFilteredMinGet(forecasts)
    nodes[nId].quality = arrFilteredMinGet(qualities)
  }

  return nodes[nId]
}

//========================================================================

/**
 * (c) Prof. Dr. Ulrich Anders
 *  WIP: go down from nId go back up to ROOT
 *
 * A function that generically runs through the node tree starting at nId
 * and performs all state actions going down
 * and carrying out all aggregations coming back up again
 * @param {object} nodes
 * @param {string} nId
 * @param {object} options
 * @returns {object} nodesNew
 */
export function nodesTreeDownUp(
  nodes,
  nId = ROOT,
  dId,
  options = {
    doPositions: true,
    doDependenciesClean: true,
    doFromByWhens: true,
    doTrafficLights: true,
    doEarliestLatest: true,
  }
) {
  const {
    doPositions,
    doDependenciesClean,
    doFromByWhens,
    doTrafficLights,
    doEarliestLatest,
  } = options

  const nodesNew = nodes
  // Extract properties of a node
  const node = nodes[nId]
  const { children } = node
  // check if node has children
  if (children.length > 0) {
    // GO DOWN
    node.children.forEach((child, index) => {
      if (doPositions) {
        nodesNew[child].position = [...nodesNew[nId].position, index + 1]
      }

      if (doDependenciesClean) {
        nodesNew[child] = nodeDependenciesClean(nodesNew, child)
      }

      if (doEarliestLatest) {
        // INHERITANCE from parents
        nodesNew[child].fromWhenEarliest = nodesNew[nId].fromWhenEarliest
        nodesNew[child].byWhenLatest = nodesNew[nId].byWhenLatest
        // consider fromWhenEarliest resulting from precedents
        nodesNew[child] = nodeFromWhenEarliestSet(nodes, child)
        // consider byWhenLatest resulting from precedents
        nodesNew[child] = nodeByWhenLatestSet(nodes, child)
      }

      // RECURSION: trigger stateCalc in each child
      nodesNew[child] = nodesTreeDownUp(nodesNew, child, dId, options)[child]
    }) // end forEach

    // COME BACK UP
    // AGGREGATE over children

    if (doFromByWhens || doEarliestLatest) {
      nodesNew[nId] = nodeFromByWhenAgg(nodesNew, nId)
      nodesNew[nId] = nodeSpanSet(nodesNew[nId])
      nodesNew[nId] = nodeProjectionSpentDegreeAgg(nodesNew, nId)
      // slack is never aggregated and therefore set to { days: 0, hours: 0 }
      nodesNew[nId].slack = { days: 0, hours: 0 }
    }

    if (doTrafficLights) {
      nodesNew[nId] = nodeTrafficLightsAgg(nodesNew, nId)
    }

    return nodesNew
  } // end if

  // STATE SETTINGS: carry out at leaf level
  if (doFromByWhens || doEarliestLatest) {
    nodesNew[nId] = nodeSpanSet(nodesNew[nId])
    nodesNew[nId] = nodeFromByWhenSet(nodesNew[nId])
    nodesNew[nId] = nodeProjectionSlackSet(nodesNew[nId])
  }
  if (doTrafficLights) {
    nodesNew[nId] = nodeTrafficLightsSet(nodesNew[nId], dId)
  }

  return nodesNew
}
