import React, { useRef, useEffect } from "react"
import { useForm, useFormState } from "react-hook-form"
import {
  Flex,
  Box,
  Text,
  Input,
  Spacer,
  chakra,
  Tooltip,
  HStack,
} from "@chakra-ui/react"
import posToStr from "../../functions/utils/posToStr"
import {
  BiCaretRight,
  BiCaretDown,
  BiArrowToRight,
  BiArrowToBottom,
} from "react-icons/bi"
import TrafficLight from "../TrafficLight"
import SwitchRound from "../SwitchRound"
import SelectPerson from "../SelectPerson"

import * as WIDTH from "../../const/widths"
import { ROOT } from "../../const/globals"

import {
  nodeActiveUpdate,
  rowUpdate,
  rowFold,
  rowForecast,
  rowQuality,
  rowMark,
  isByWhenPinnedUpdate,
  // isFromWhenPinnedUpdate,
} from "../../redux/actions/project"
import { levelColorSet } from "../../functions/helpers/levelColorSet"
import { durToDurStr } from "../../functions/timeHandler/durations"
import {
  tpAbbrD,
  tpAbbrHS,
  tpAbbrHE,
} from "../../functions/timeHandler/timePointAbbreviate"
import locToISO from "../../functions/timeHandler/locToISO"
import {
  ABBR_D_MESSAGE,
  ABBR_D_REGEX,
  ABBR_H_MESSAGE,
  ABBR_H_REGEX,
  PERCENT_MESSAGE,
  PERCENT_REGEX,
  DURATION_MESSAGE,
  DURATION_REGEX,
  PRECEDENTS_MESSAGE,
  PRECEDENTS_REGEX,
} from "../../const/messages"
import objDeepTrim from "../../functions/utils/objDeepTrim"

const SheetRow = ({
  row,
  node,
  isTop = false,
  isBottom = false,
  statusCurrent,
  isStatusLocked,
  params,
  isResetForm = false,
  dispatch,
  isExamOn,
  isDebugOn,
}) => {
  //=================================================================

  const inputRef = useRef(null)
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const { dirtyFields } = useFormState({ control })

  // console.log({ dirtyFields: JSON.stringify(dirtyFields) })

  const {
    nId,
    pId,
    children,
    position,
    who,
    deliversWhat,
    fromWhen,
    toProjectionISO,
    byWhen,
    isByWhenPinned,
    toWhom,
    deadline,
    isDeadlineDue,
    //
    degree,
    isDelivered,
    forecast,
    quality,
    comment,
    isCommentRequired,
    precedents,
    precedentsStr,
    precedentsStrMore,
    dependents,
    //
    span,
    projection,
    slack,
    spent,
    spentStr,
    projectionStr,
    spanStr,
    slackStr,
    fromSlackISO,
    //
    isIgnored,
    isUnresolved,
    isActive,
    isFolded,
    levelMax,
  } = node

  const { isDetailsOn, isSlimOn, dayEnd } = params
  const isSlimBin = isSlimOn ? 1 : 0

  const hasChildren = children.length > 0 || nId === ROOT
  const hasPosition = position.length > 0
  const level = position.length

  const isLocked = node.isLocked || isStatusLocked

  // ----------------------------------------------------------------
  useEffect(() => {
    if (isResetForm) {
      reset()
    }
  }, [isResetForm, reset, node])

  // ----------------------------------------------------------------
  const dataRemoveUnchanged = (data) => {
    // console.log("dataRemoveUnchanged: ", { data })
    data.byWhen =
      data.byWhenH !== undefined && data.byWhenH !== ""
        ? data.byWhenD + "|" + data.byWhenH
        : data.byWhenD
    delete data.byWhenD
    delete data.byWhenH
    if (data.byWhen === undefined) {
      delete data.byWhen
    }

    for (const key in data) {
      let valueBefore = node[key]
      // console.log({ key, value: data[key], valueBefore })
      // !!valueBefore === true → valueBefore !== undefined && valueBefore !== ""
      if (key === "byWhen" && !!valueBefore) {
        // console.log("dataRemoveUnchanged byWhen")
        if (
          data[key] === "" ||
          locToISO(data[key], statusCurrent) === valueBefore
        ) {
          delete data[key]
        }
      } else if ((key === "spent" || key === "plan") && !!valueBefore) {
        // console.log("dataRemoveUnchanged spent || plan")
        valueBefore = durToDurStr(valueBefore)
        if (data[key] === "" || data[key] === valueBefore) {
          delete data[key]
        }
      } else if (key === "degree") {
        // degree is internally an int
        data[key] = parseInt(data[key])

        if (data[key] === valueBefore) {
          delete data[key]
        }
      } else if (data[key] === valueBefore) {
        // console.log("dataRemoveUnchanged else: ", {
        //   key,
        //   valueBefore,
        //   dataKey: data[key],
        // })
        delete data[key]
      }
    }

    return data
  }
  // ----------------------------------------------------------------
  const onSubmit = (data) => {
    // moves to hidden button. Submit is because this causes an onBlur.
    inputRef.current.focus()
  }

  const onBlur = (data) => {
    // console.log("SheetRow 1: ", { ...data })

    objDeepTrim(data)

    // merge data.precedentsStr and data.precedentsStrMore
    if (data.precedentsStrMore !== undefined) {
      if (data.precedentsStrMore !== "") {
        data.precedentsStr = data.precedentsStr + ", " + data.precedentsStrMore
      }
      delete data.precedentsStrMore
    }
    // console.log("SheetRow 2: ", { ...data })

    const payload = { nId, data: dataRemoveUnchanged(data) }
    // console.log("SheetRow 3: ", { ...data })
    dispatch(rowUpdate(payload))
  }

  const onClickFocus = (e) => {
    if (!isActive) {
      const payload = { nId }
      dispatch(nodeActiveUpdate(payload))
    }
  }

  // ----------------------------------------------------------------

  const onClickFold = (event) => {
    const payload = { nId }
    dispatch(rowFold(payload))
  }

  // ----------------------------------------------------------------
  const onClickMark = () => {
    const payload = { nId }
    if (isUnresolved) {
      payload.isUnresolved = false
      payload.isIgnored = true
    } else if (isIgnored) {
      payload.isUnresolved = false
      payload.isIgnored = false
    } else {
      payload.isUnresolved = true
      payload.isIgnored = false
    }
    dispatch(rowMark(payload))
  }

  // ----------------------------------------------------------------
  const onClickForecast = () => {
    const payload = { nId }
    dispatch(rowForecast(payload))
  }

  // ----------------------------------------------------------------
  const onClickQuality = () => {
    const payload = { nId }
    dispatch(rowQuality(payload))
  }

  // ----------------------------------------------------------------
  const onClickByWhenPin = () => {
    const payload = { nId }
    dispatch(isByWhenPinnedUpdate(payload))
  }

  // ----------------------------------------------------------------

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Box
        bg={isActive && !isLocked ? "pink.50" : row % 2 ? "white" : "grey.100"}
        _hover={{ bg: isActive ? "pink.60" : "grey.200" }}
        fontFamily="Fira Sans Condensed"
        onClick={isLocked ? null : onClickFocus}
        cursor={isLocked ? "default" : "pointer"}
      >
        <Flex color={isIgnored || isUnresolved ? "grey.400" : "inherit"}>
          <Box w={WIDTH.MARK[isSlimBin]} textAlign="center">
            {!hasChildren ? (
              <SwitchRound
                color="red.500"
                colorCode={isIgnored ? 2 : isUnresolved ? 1 : 0}
                onClick={onClickMark}
                isDisabled={isLocked}
              />
            ) : (
              <Box>&nbsp;</Box>
            )}
            {isDetailsOn && isDebugOn && (
              <Text fontSize="sm" fontWeight="normal">
                {row}
              </Text>
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box
            w={WIDTH.POSITION[isSlimBin]}
            px={1}
            {...levelColorSet({
              prop: "color",
              level,
              levelMax,
              isIgnored,
              isUnresolved,
            })}
            fontFamily="Inconsolata"
            fontWeight="700"
          >
            <Flex align="flex-start">
              {hasChildren ? (
                isFolded ? (
                  <chakra.button
                    type="button"
                    outline="none"
                    onClick={onClickFold}
                    position="relative"
                    top="-2px"
                  >
                    <BiCaretRight />
                  </chakra.button>
                ) : (
                  <chakra.button
                    type="button"
                    outline="none"
                    onClick={onClickFold}
                    position="relative"
                    top="-2px"
                  >
                    <BiCaretDown />
                  </chakra.button>
                )
              ) : (
                <chakra.button type="button" outline="none" visibility="hidden">
                  <BiCaretRight />
                </chakra.button>
              )}
              {hasPosition ? (
                <Box fontWeight="700">{posToStr(position)}</Box>
              ) : (
                <Box fontWeight="700">TOTAL</Box>
              )}
            </Flex>
            {isDetailsOn && isDebugOn && (
              <Text fontSize="sm" fontWeight="normal" ml="16px">
                {nId}
              </Text>
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box w={WIDTH.WHO[isSlimBin]} px={1}>
            <SelectPerson
              wId={nId}
              name="who"
              value={who}
              isIgnored={isIgnored || isUnresolved}
              isLocked={isLocked}
            />
            {isDetailsOn && (
              <Flex
                justify="flex-end"
                fontSize="sm"
                color={isIgnored || isUnresolved ? "grey.400" : "grey.700"}
                fontWeight="normal"
              >
                <Tooltip
                  label={
                    level === 0
                      ? "FROM: Starting date of project."
                      : "FROM: Earliest starting date of DELIVERS WHAT depending on parents and precedents. There is at least 1h gap to a precedent."
                  }
                  placement="bottom-start"
                  hasArrow
                >
                  <Text ml={3} mr={1}>
                    From:
                  </Text>
                </Tooltip>
                <Text w="75%" textAlign="right">
                  {tpAbbrD(fromWhen, statusCurrent)}
                </Text>
                <Text w="20px" textAlign="right">
                  {tpAbbrHS(fromWhen) !== "" && "|"}
                  {tpAbbrHS(fromWhen)}
                </Text>
              </Flex>
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box w={WIDTH.WHAT[isSlimBin]} px={1}>
            <Input
              type="text"
              {...register("deliversWhat")}
              onBlur={dirtyFields?.deliversWhat ? handleSubmit(onBlur) : null}
              defaultValue={deliversWhat}
              isDisabled={isLocked}
            />
            {isDetailsOn && (
              <Flex
                justify="flex-start"
                fontSize="sm"
                color={isIgnored || isUnresolved ? "grey.400" : "grey.700"}
                fontWeight="normal"
              >
                <Tooltip
                  label={
                    hasChildren
                      ? "PROJECTION: Aggregated Projection. Projection = Σ Projections."
                      : "PROJECTION: Linear extrapolation of SPENT. Projection = Spent / Degree."
                  }
                  placement="bottom-start"
                  hasArrow
                >
                  <Text ml={3} mr={1}>
                    Proj:
                  </Text>
                </Tooltip>
                <Text w="35%">{projectionStr}</Text>
                {!children.length && (
                  <Flex
                    justify="flex-start"
                    fontSize="sm"
                    color={isIgnored || isUnresolved ? "grey.400" : "grey.700"}
                    fontWeight="normal"
                  >
                    <Tooltip
                      label="SLACK: Duration that is currently not needed. Slack = Span − Projection."
                      placement="bottom-start"
                      hasArrow
                    >
                      <Text ml={3} mr={1}>
                        Slack: {slackStr}
                      </Text>
                    </Tooltip>
                  </Flex>
                )}
              </Flex>
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box w={WIDTH.WHEN[isSlimBin]} pr={2}>
            <Flex
              {...levelColorSet({
                prop: "color",
                level,
                levelMax,
                isIgnored,
                isUnresolved,
              })}
              justify="space-between"
            >
              <chakra.button
                onClick={onClickByWhenPin}
                type="button"
                outline="none"
                mb={2}
              >
                {isByWhenPinned ? <BiArrowToBottom /> : <BiArrowToRight />}
              </chakra.button>
              {!hasChildren || isByWhenPinned ? (
                <>
                  <Tooltip
                    isOpen={errors?.byWhenD}
                    label={errors?.byWhenD ? errors?.byWhenD.message : ""}
                    placement="top"
                    hasArrow
                    bg="red.500"
                  >
                    <Input
                      type="text"
                      {...register("byWhenD", {
                        pattern: {
                          value: ABBR_D_REGEX,
                          message: ABBR_D_MESSAGE,
                        },
                      })}
                      defaultValue={tpAbbrD(byWhen, statusCurrent)}
                      isDisabled={isLocked}
                      onBlur={
                        dirtyFields?.byWhenD ? handleSubmit(onBlur) : null
                      }
                      textAlign="right"
                      fontFamily="Inconsolata"
                      fontWeight="700"
                    />
                  </Tooltip>
                  {tpAbbrHE(byWhen, dayEnd) === "" ? (
                    <Box
                      display="inline-block"
                      minW="8px"
                      fontFamily="Inconsolata"
                      fontWeight="700"
                    ></Box>
                  ) : (
                    "|"
                  )}
                  <Tooltip
                    isOpen={errors?.byWhenH}
                    label={errors?.byWhenH ? errors?.byWhenH.message : ""}
                    placement="top"
                    hasArrow
                    bg="red.500"
                  >
                    <Input
                      flex="0 0 auto"
                      w="18px"
                      type="text"
                      {...register("byWhenH", {
                        pattern: {
                          value: ABBR_H_REGEX,
                          message: ABBR_H_MESSAGE,
                        },
                      })}
                      defaultValue={tpAbbrHE(byWhen, dayEnd)}
                      onBlur={
                        dirtyFields?.byWhenH ? handleSubmit(onBlur) : null
                      }
                      textAlign="right"
                      isDisabled={isLocked}
                      fontFamily="Inconsolata"
                      fontWeight="700"
                    />
                  </Tooltip>
                </>
              ) : (
                <Flex fontFamily="Inconsolata" fontWeight="700">
                  <Text textAlign="right">
                    {tpAbbrD(byWhen, statusCurrent)}
                  </Text>
                  <Text flex="0 0 auto" w="26px" textAlign="right">
                    {tpAbbrHE(byWhen, dayEnd) === "" ? "" : "|"}
                    {tpAbbrHE(byWhen, dayEnd)}
                  </Text>
                </Flex>
              )}
            </Flex>
            {isDetailsOn && (
              <Flex
                justify="flex-start"
                fontSize="sm"
                color={isIgnored || isUnresolved ? "grey.400" : "grey.700"}
                fontWeight="normal"
              >
                <Tooltip
                  label="SPAN: Duration between BY WHEN and FROM. Span = By When − From."
                  placement="bottom-start"
                  hasArrow
                >
                  <Text mx={6} mr={1}>
                    Span:
                  </Text>
                </Tooltip>{" "}
                <Text>{spanStr}</Text>
              </Flex>
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box w={WIDTH.WHO[isSlimBin]} px={1}>
            <SelectPerson
              wId={nId}
              name="toWhom"
              value={toWhom}
              isIgnored={isIgnored || isUnresolved}
              isLocked={isLocked}
            />
          </Box>

          <Spacer bg="transparent" />

          <Box w={WIDTH.SPENT[isSlimBin]} px={1} textAlign="right">
            {hasChildren ? (
              <Text
                {...levelColorSet({
                  prop: "color",
                  level,
                  levelMax,
                  isIgnored,
                  isUnresolved,
                })}
              >
                {spentStr}
              </Text>
            ) : (
              <Tooltip
                isOpen={errors?.spent}
                label={errors?.spent ? errors?.spent.message : ""}
                placement="top"
                hasArrow
                bg="red.500"
              >
                <Input
                  type="text"
                  {...register("spent", {
                    pattern: {
                      value: DURATION_REGEX,
                      message: DURATION_MESSAGE,
                    },
                  })}
                  defaultValue={spentStr}
                  isDisabled={isLocked}
                  onBlur={dirtyFields?.spent ? handleSubmit(onBlur) : null}
                  textAlign="right"
                  {...levelColorSet({
                    prop: "color",
                    level,
                    levelMax,
                    isIgnored,
                    isUnresolved,
                  })}
                />
              </Tooltip>
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box
            w={WIDTH.DEGREE[isSlimBin]}
            px={1}
            textAlign="right"
            fontFamily="Inconsolata"
            fontWeight="700"
          >
            {hasChildren ? (
              !isExamOn ? (
                <Text
                  {...levelColorSet({
                    prop: "color",
                    level,
                    levelMax,
                    isIgnored,
                    isUnresolved,
                  })}
                >
                  {degree.toString()}
                </Text>
              ) : (
                <Text
                  h="24px"
                  w="100%"
                  border={isExamOn ? "2px solid goldenrod" : "none"}
                ></Text>
              )
            ) : (
              <Tooltip
                isOpen={errors?.degree}
                label={errors?.degree ? errors.degree.message : ""}
                placement="top"
                hasArrow
                bg="red.500"
              >
                <Input
                  type="number"
                  {...register("degree", {
                    min: { value: 0, message: "Must be >0." },
                    pattern: {
                      value: PERCENT_REGEX,
                      message: PERCENT_MESSAGE,
                    },
                  })}
                  defaultValue={degree.toString()}
                  isDisabled={isLocked}
                  onBlur={dirtyFields?.degree ? handleSubmit(onBlur) : null}
                  textAlign="right"
                  {...levelColorSet({
                    prop: "color",
                    level,
                    levelMax,
                    isIgnored,
                    isUnresolved,
                  })}
                />
              </Tooltip>
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box w={WIDTH.DEADLINE[isSlimBin]} textAlign="center">
            {((!hasChildren && (isDeadlineDue || degree >= 100)) ||
              (hasChildren && deadline !== 0)) && (
              <TrafficLight
                isExamOn={isExamOn}
                colorCode={deadline}
                isIgnored={isIgnored || isUnresolved}
                isDisabled={isLocked}
              />
            )}
          </Box>

          <Spacer bg="transparent" />

          <Box w={WIDTH.FORECAST[isSlimBin]} textAlign="center">
            {(!hasChildren && !isDeadlineDue && degree < 100) ||
            (hasChildren && forecast !== 0) ? (
              !hasChildren ? (
                <TrafficLight
                  isExamOn={isExamOn}
                  colorCode={forecast}
                  onClick={onClickForecast}
                  isIgnored={isIgnored || isUnresolved}
                  isDisabled={isLocked}
                />
              ) : (
                <TrafficLight
                  isExamOn={isExamOn}
                  colorCode={forecast}
                  isIgnored={isIgnored || isUnresolved}
                  isDisabled={isLocked}
                />
              )
            ) : null}
          </Box>

          <Spacer bg="transparent" />

          <Box w={WIDTH.QUALITY[isSlimBin]} textAlign="center">
            {(!hasChildren && isDelivered) || (hasChildren && quality !== 0) ? (
              !hasChildren ? (
                <TrafficLight
                  isExamOn={isExamOn}
                  colorCode={quality}
                  onClick={onClickQuality}
                  isIgnored={isIgnored || isUnresolved}
                  isDisabled={isLocked}
                />
              ) : (
                <TrafficLight
                  isExamOn={isExamOn}
                  colorCode={quality}
                  isIgnored={isIgnored || isUnresolved}
                  isDisabled={isLocked}
                />
              )
            ) : null}
          </Box>

          {!isSlimOn && <Spacer bg="transparent" />}

          {!isSlimOn && (
            <Box w={WIDTH.COMMENT[isSlimBin]} px={1}>
              <Tooltip
                isOpen={isCommentRequired || errors?.comment}
                label={
                  isCommentRequired & (comment.length < 3)
                    ? "Comment required for colors other than green."
                    : errors?.comment
                    ? errors?.comment.message
                    : ""
                }
                placement="top-start"
                hasArrow
                bg="red.500"
              >
                <Input
                  type="text"
                  {...register("comment", {
                    required:
                      isCommentRequired &&
                      "Comment required for colors other than green.",
                  })}
                  defaultValue={comment}
                  isDisabled={isLocked}
                  onBlur={dirtyFields?.comment ? handleSubmit(onBlur) : null}
                />
              </Tooltip>

              {isDetailsOn && level > 0 && (
                <Flex
                  justify="flex-end"
                  fontSize="sm"
                  color={isIgnored || isUnresolved ? "grey.400" : "grey.700"}
                  fontWeight="normal"
                >
                  <Tooltip
                    label="MORE PRECEDENTS: Precedents that did not fit the line above."
                    placement="bottom-start"
                    hasArrow
                  >
                    <Text ml={3} mr={1} flex="0 0 auto">
                      More Precs:
                    </Text>
                  </Tooltip>
                  <Tooltip
                    isOpen={errors?.precedentsStrMore}
                    label={
                      errors?.precedentsStrMore
                        ? errors?.precedentsStrMore.message
                        : ""
                    }
                    placement="top"
                    hasArrow
                    bg="red.500"
                  >
                    <Input
                      type="text"
                      {...register("precedentsStrMore", {
                        pattern: {
                          value: PRECEDENTS_REGEX,
                          message: PRECEDENTS_MESSAGE,
                        },
                      })}
                      defaultValue={precedentsStrMore}
                      isDisabled={isLocked}
                      onBlur={
                        dirtyFields?.precedentsStrMore
                          ? handleSubmit(onBlur)
                          : null
                      }
                    />
                  </Tooltip>
                </Flex>
              )}
            </Box>
          )}

          {!isSlimOn && <Spacer bg="transparent" />}

          {!isSlimOn && (
            <Box w={WIDTH.PRECS[isSlimBin]} px={1}>
              {level > 0 && (
                <Tooltip
                  isOpen={errors?.precedentsStr}
                  label={
                    errors?.precedentsStr ? errors?.precedentsStr.message : ""
                  }
                  placement="top"
                  hasArrow
                  bg="red.500"
                >
                  <Input
                    type="text"
                    {...register("precedentsStr", {
                      pattern: {
                        value: PRECEDENTS_REGEX,
                        message: PRECEDENTS_MESSAGE,
                      },
                    })}
                    defaultValue={precedentsStr}
                    isDisabled={isLocked}
                    onBlur={
                      dirtyFields?.precedentsStr ? handleSubmit(onBlur) : null
                    }
                  />
                </Tooltip>
              )}
            </Box>
          )}

          {!isLocked && <chakra.button w="0" ref={inputRef}></chakra.button>}
        </Flex>
        {isSlimOn && (
          <Flex>
            <Flex color="grey.600" fontSize="sm" w="75%" mr={4} ml={12}>
              <Text textAlign="right" fontSize="sm" color="blue.600" mr={2}>
                COMMENT:
              </Text>
              <Input
                // textAlign="right"
                type="text"
                {...register("comment")}
                defaultValue={comment}
                onBlur={dirtyFields?.comment ? handleSubmit(onBlur) : null}
              />
            </Flex>
            <Flex color="grey.600" fontSize="sm" w="25%">
              <Text textAlign="right" fontSize="sm" color="blue.600" mr={2}>
                PRECS:
              </Text>
              <Input
                type="text"
                {...register("precedentsStr", {
                  pattern: {
                    value: PRECEDENTS_REGEX,
                    message: PRECEDENTS_MESSAGE,
                  },
                })}
                defaultValue={precedentsStr}
                isDisabled={isLocked}
                onBlur={
                  dirtyFields?.precedentsStr ? handleSubmit(onBlur) : null
                }
              />
            </Flex>
          </Flex>
        )}
        {isDebugOn && (
          <HStack
            color="grey.600"
            fontWeight="normal"
            fontSize="sm"
            ml="184px"
            mr={0}
            mb={2}
            spacing={4}
          >
            <Text>fromWhen:</Text>
            <Text>{fromWhen.replace(":00:00.000Z", "")}</Text>
            <Text>byProj:</Text>
            <Text>{toProjectionISO.replace(":00:00.000Z", "")}</Text>
            <Text>fromSlack:</Text>
            <Text>{fromSlackISO.replace(":00:00.000Z", "")}</Text>
            <Text>byWhen: </Text>
            <Text>{byWhen.replace(":00:00.000Z", "")}</Text>
            <Text>span: </Text>
            <Text>{span.days + ", " + span.hours}</Text>
            <Text>proj: </Text>
            <Text>{projection.days + ", " + projection.hours}</Text>
            <Text>slack: </Text>
            <Text>{slack.days + ", " + slack.hours}</Text>
            <Text>spent: </Text>
            <Text>{spent.days + ", " + spent.hours}</Text>
            <Text>pId: </Text>
            <Text>{pId}</Text>
            <Text>children: </Text>
            <Text>{children.join(", ")}</Text>
            <Text>precedents: </Text>
            <Text>{precedents.join(", ")}</Text>
            <Text>dependents: </Text>
            <Text>{dependents.join(", ")}</Text>
          </HStack>
        )}
      </Box>
    </form>
  )
}

export default SheetRow
