/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Calculates the color code based on a value and parameters
 * @param {number} value
 * @param {array} params
 * @returns {number} colorCode
 */
function colorCodeCalc(value, params, isInverse = false) {
  if (!isInverse) {
    if (value === 0) {
      return 0
    }
    if (value >= params[0] && value < params[1]) {
      return 1
    } else if (value >= params[1] && value < params[2]) {
      return 2
    } else if (value >= params[2] && value < params[3]) {
      return 3
    } else if (value >= params[3]) {
      return 4
    }
  } else {
    if (value >= params[0] && value <= params[1]) {
      return 4
    } else if (value > params[1] && value <= params[2]) {
      return 3
    } else if (value > params[2] && value <= params[3]) {
      return 2
    } else if (value > params[3]) {
      return 1
    }
  }
  return 0
}

export default colorCodeCalc
