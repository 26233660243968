import { createSelector } from "reselect"
import { depsArrToPosStr } from "../../functions/nodes/nodesDependencies"
import { dateDurationAdd } from "../../functions/timeHandler/dateDurationAdd"
import { isoDateOneHourAdd } from "../../functions/nodes/nodesAccumulations2"
import { durToDurStr } from "../../functions/timeHandler/durations"
import { DAY_START, DAY_END } from "../../const/globals"
import parseISO from "date-fns/parseISO"
import isEqual from "date-fns/isEqual"
import isAfter from "date-fns/isAfter"
import isBefore from "date-fns/isBefore"

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Gets nodes from current status
 * @param {object} state
 * @returns {object} nodes
 */
const getNodesCurrent = (state) =>
  state.project.statuses[state.project.statusCurrent].nodes

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Gets rows from current status
 * @param {object} state
 * @returns {array} rows
 */
const getRowsCurrent = (state) =>
  state.project.statuses[state.project.statusCurrent].rows

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Gets current status from state
 * @param {object} state
 * @returns {object} statusCurrent
 */
const getStatusCurrent = (state) => state.project.statusCurrent

/**
 * v1.0.0: (c) Prof. Dr. Ulrich Anders
 *
 * Generates extra infos for nodes from current status nodes
 * by help of a selector function from reselect
 * @param {array} [getNodesCurrent, getRowsCurrent, getStatusCurrent]
 * @param {function} selector function
 * @returns {} statusCurrent
 */
const getNodesExtraInfos = createSelector(
  [getNodesCurrent, getRowsCurrent, getStatusCurrent],
  (nodesCurrent, rowsCurrent, statusCurrent) => {
    let levelMax = Math.max(
      ...rowsCurrent.map((nId) => nodesCurrent[nId].position.length)
    )

    let nodesExtraInfos = {}
    for (let [nId] of Object.entries(nodesCurrent)) {
      const fromWhenDate = parseISO(nodesCurrent[nId].fromWhen)
      const byWhenDate = parseISO(nodesCurrent[nId].byWhen)

      let toProjectionDate = dateDurationAdd(
        fromWhenDate,
        nodesCurrent[nId].projection,
        DAY_START,
        DAY_END
      )

      let toProjectionISO = toProjectionDate.toISOString()

      let fromSlackISO = isoDateOneHourAdd(
        toProjectionISO,
        DAY_START,
        DAY_END
      ).toISOString()

      // toProjectionISO or fromSlackIOS cannot be after byWhen
      if (
        isEqual(toProjectionDate, byWhenDate) ||
        isAfter(toProjectionDate, byWhenDate)
      ) {
        toProjectionISO = nodesCurrent[nId].byWhen
        // TODO: check logic
        // one day + 1 hour behind byWhen to move out of the way
        let fromSlackDate = dateDurationAdd(
          toProjectionDate,
          { days: 1, hours: 1 },
          DAY_START,
          DAY_END
        )
        fromSlackISO = fromSlackDate.toISOString()
      }

      const spanStr = durToDurStr(nodesCurrent[nId].span)
      const spentStr = durToDurStr(nodesCurrent[nId].spent)
      const slackStr = durToDurStr(nodesCurrent[nId].slack)
      const projectionStr = durToDurStr(nodesCurrent[nId].projection)

      const isDeadlineDue = isBefore(
        parseISO(nodesCurrent[nId].byWhen),
        parseISO(statusCurrent)
      )

      const isDelivered = nodesCurrent[nId].degree === 100

      const isCommentRequired =
        nodesCurrent[nId].children.length > 0
          ? false
          : (nodesCurrent[nId].deadline > 0 &&
              nodesCurrent[nId].deadline < 4) ||
            (nodesCurrent[nId].forecast > 0 &&
              nodesCurrent[nId].forecast < 4) ||
            (nodesCurrent[nId].quality > 0 && nodesCurrent[nId].quality < 4)

      let precedents = nodesCurrent[nId].precedents

      let precedentsDefault = []
      let precedentsMore = []
      let precedentsLengthAct = 0
      let precedentsLengthMax = 2

      precedents.forEach((precedent) => {
        if (
          precedentsLengthAct + nodesCurrent[precedent].position.length <=
          precedentsLengthMax
        ) {
          precedentsDefault.push(precedent)
        } else {
          precedentsMore.push(precedent)
        }
        precedentsLengthAct += nodesCurrent[precedent].position.length
      })

      const precedentsStr = depsArrToPosStr(nodesCurrent, precedentsDefault)
      const precedentsStrMore = depsArrToPosStr(nodesCurrent, precedentsMore)

      nodesExtraInfos[nId] = {
        levelMax,
        precedentsStr,
        precedentsStrMore,
        spanStr,
        spentStr,
        toProjectionISO,
        projectionStr,
        fromSlackISO,
        slackStr,
        isDeadlineDue,
        isDelivered,
        isCommentRequired,
      }
    }
    return nodesExtraInfos
  }
)

export default getNodesExtraInfos
