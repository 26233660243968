import React from "react"
import { connect } from "react-redux"
import SEO from "../../components/SEO"
import { Container, Flex, Box } from "@chakra-ui/react"

import ScoreRadarMaturities from "./ScoreRadarMaturities"
import ScoreLegend from "./ScoreLegend"
import ScoreQuestionnairePlain from "./ScoreQuestionnairePlain"
import ScoreQuestionnaireSimpleBar from "./ScoreQuestionnaireSimpleBar"

const PageScore = ({ dispatch }) => {
  // ==========================================================================

  // ==========================================================================
  return (
    <Container minHeight={"640px"} maxW="100%" mb={4}>
      <SEO
        title="Best Practices Score | THE PROJECT STATUS"
        urlPage="/score"
        description=""
      ></SEO>

      <Flex wrap="wrap">
        <Box
          w={["100%", "100%", "100%", "50%", "50%"]}
          order={[2, 2, 2, 1]}
          fontFamily="Fira Sans Condensed"
          pr={[0, 0, 0, 4]}
          mt={"43px"}
        >
          <Box display={["none", "none", "none", "block"]}>
            <ScoreRadarMaturities />
          </Box>
          <ScoreLegend />
        </Box>

        <Box
          w={["100%", "100%", "100%", "50%", "50%"]}
          order={[1, 1, 1, 2]}
          pl={[0, 0, 0, 4]}
        >
          <Box display={["block", "block", "block", "none"]}>
            <ScoreQuestionnairePlain />
          </Box>
          <Box display={["none", "none", "none", "block"]}>
            <ScoreQuestionnaireSimpleBar />
          </Box>
        </Box>
      </Flex>
    </Container>
  )
}

export default connect()(PageScore)
