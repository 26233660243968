import isEmpty from "lodash.isempty"

/**
 * v1.0.1: (c) Prof. Dr. Ulrich Anders
 *
 * Deep trims all strings in an object and
 * replaces several white spaces with one space.
 * @param {object} obj
 * @returns {}
 */
function objDeepTrim(obj) {
  if (!isEmpty(obj)) {
    for (var key in obj) {
      var value = obj[key],
        type = typeof value
      if (
        value != null &&
        (type === "string" || type === "object") &&
        obj.hasOwnProperty(key)
      ) {
        if (type === "object") {
          objDeepTrim(obj[key])
        } else {
          obj[key] = obj[key].trim()
          obj[key] = obj[key].replace(/\s\s+/g, " ")
        }
      }
    }
  }
}

export default objDeepTrim
