/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Returns the string with a strCapitalized first character
 * @param {string} s
 * @return {string} s
 */
const strCapitalize = (s) => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export default strCapitalize
