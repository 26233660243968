import COLORS from "../../../theme/colors"
import ClearIndicatorStyles from "../../SelectPerson/style/ClearIndicatorStyles"

// https://react-select.com/styles
const customStyles = {
  control: (provided) => ({ display: "flex" }),
  clearIndicator: ClearIndicatorStyles,
  indicatorsContainer: (provided, state) => ({
    position: "relative",
    left: "4px",
    color: "#bdbdbd", //gre.400
    background: "transparent",
    "&:hover": { color: COLORS.red[500] },
  }),
  // dropdownIndicator: (provided, state) => ({}),
  // IndicatorSeparator: (provided, state) => ({}),
  container: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderColor: COLORS.grey[400],
  }),
  multiValue: (provided) => ({
    ...provided,
    background: "transparent",
    margin: "0",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: "inherit",
    padding: "0",
    paddingLeft: "0",
    color: state.selectProps.isLocked ? "inherit" : "inherit",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    fontSize: "85%",
    color: COLORS.grey[400],
    padding: 0,
    marginRight: "0.25rem",
    "&:hover": { background: "transparent" },
  }),
  placeholder: (provided) => ({
    ...provided,
    margin: "0",
  }),
  menu: (provided) => ({ ...provided, width: 500, borderRadius: 0 }),
  option: (provided) => ({
    ...provided,
    margin: "0",
    padding: "0 4px",
  }),
  input: (provided) => ({}),
}

export default customStyles
