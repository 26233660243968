import React from "react"
import { connect } from "react-redux"

import StatusTravel from "../StatusTravel"
import Person from "../Person"
import SwitchFirstName from "../SwitchFirstName"

const Persons = ({
  projectCurrent,
  params,
  isStatusLocked,
  isResetForm = false,
  dispatch,
}) => {
  //=================================================================
  const { persons } = projectCurrent
  const { isNameFirstPreferred } = params

  //----------------------------------------------------------------
  const renderPersons = () => {
    const length = Object.keys(persons).length
    const name = isNameFirstPreferred ? "nameFirst" : "nameLast"
    let aPersons = Object.keys(persons)

    aPersons = aPersons.slice().sort((a, b) => {
      if (persons[a][name] === "") {
        return 1
      } else if (persons[b][name] === "") {
        return -1
      }
      if (persons[a][name] < persons[b][name]) {
        return -1
      } else if (persons[a][name] > persons[b][name]) {
        return 1
      } else {
        return 0
      }
    })

    aPersons = aPersons.map((sId, index) => (
      <Person
        key={sId}
        person={persons[sId]}
        isLast={index === length - 1}
        isStatusLocked={isStatusLocked}
        isResetForm={isResetForm}
        // isResetForm={}
        dispatch={dispatch}
      />
    ))

    return aPersons
  }

  //=================================================================
  return (
    <>
      <StatusTravel />
      <SwitchFirstName />
      {renderPersons()}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    projectCurrent: state.project.statuses[state.project.statusCurrent],
    params: state.project.params,
    isStatusLocked:
      state.project.statuses[state.project.statusCurrent].setup.isStatusLocked,
    messageFromApp: state.app.messageFromApp,
    messageFromProject: state.project.messageFromProject,
    isResetForm: state.project.isResetForm,
  }
}

export default connect(mapStateToProps)(Persons)
