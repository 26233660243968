/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * @param {object} score
 * @param {string} qId
 * @returns {object} score.question[qId]
 */
export function scoreAggregate(score, qId = "00") {
  if (
    score.questions[qId].children &&
    score.questions[qId].children.length > 0
  ) {
    // GO DOWN
    score.questions[qId].children.forEach((cId) => {
      score = scoreAggregate(score, cId)
    })
    // COME BACK UP AND AGGREGATE
    score.questions[qId].children.forEach((cId, index) => {
      if (index === 0) {
        score.questions[qId].scoreMax = score.questions[cId].scoreMax
      } else {
        score.questions[qId].scoreMax += score.questions[cId].scoreMax
      }
    })

    score.questions[qId].children.forEach((cId, index) => {
      if (index === 0) {
        score.questions[qId].score = score.questions[cId].score
      } else {
        score.questions[qId].score += score.questions[cId].score
      }
    })

    score = questionScorePercentCalc(score, qId)
  } else {
    // CALC LEAVES
    // calc score
    score = questionScoreCalc(score, qId)
    score = questionScoreMaxCalc(score, qId)
    score = questionScorePercentCalc(score, qId)
  }

  return score
}

function questionScoreCalc(score, qId) {
  score.questions[qId].score =
    score.questions[qId].answer === 0
      ? 0
      : score.questions[qId].weight * (score.questions[qId].answer - 1)
  return score
}

function questionScoreMaxCalc(score, qId) {
  score.questions[qId].scoreMax =
    score.questions[qId].answer === 0
      ? 0
      : score.questions[qId].weight * (score.questions[qId].answerMax - 1)
  return score
}

function questionScorePercentCalc(score, qId) {
  score.questions[qId].scorePercent =
    score.questions[qId].scoreMax === 0
      ? 0
      : Math.round(
          (score.questions[qId].score / score.questions[qId].scoreMax) * 100,
          0
        )
  return score
}
