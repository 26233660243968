const Button = {
  baseStyle: {
    lineHeight: "1.2",
    borderRadius: "md",
    fontWeight: "semibold",
    _focus: { boxShadow: "outline" },
    _disabled: { opacity: 0.4, cursor: "not-allowed", boxShadow: "none" },
    _hover: { _disabled: { bg: "initial" } },
  },
  variants: {
    unstyled: {
      bg: "none",
      color: "inherit",
      display: "inline",
      lineHeight: "inherit",
      m: 0,
      p: 0,
    },
    pc: {
      bg: "none",
      color: "inherit",
      display: "inline",
      lineHeight: 1,
      textAlign: "center",
      m: 0,
      mx: 4,
      px: 3,
      h: "24px",
      fontWeight: "medium",
      borderWidth: "1px",
      borderColor: "grey.300",
      borderRadius: "0",
      _hover: { borderColor: "grey.500" },
    },
    icon: {
      outline: "none",
      bg: "none",
      color: "inherit",
      display: "inline",
      lineHeight: 1,
      textAlign: "center",
      m: 0,
      py: 0,
      px: 0,
      w: "24px",
      h: "24px",
      minW: "0",
      boxSizing: "content-box",
      fontWeight: "normal",
      borderWidth: "1px",
      borderColor: "grey.300",
      borderRadius: "0",
      _hover: { borderColor: "grey.500" },
    },
  },
  sizes: {
    lg: { h: 12, minW: 12, fontSize: "lg", px: 6 },
    md: { h: 10, minW: 10, fontSize: "md", px: 4 },
    sm: { h: 8, minW: 8, fontSize: "sm", px: 3 },
    xs: { h: 6, minW: 6, fontSize: "xs", px: 2 },
  },
  defaultProps: { variant: "pc", size: "md", colorScheme: "gray" },
}

export default Button
