import React, { useState, useLayoutEffect, useRef } from "react"
import { connect } from "react-redux"
import { Box } from "@chakra-ui/react"
import SheetRow from "../SheetRow"
import nodeIsTopCalc from "../../functions/nodes/nodeIsTopCalc"
import nodeIsBottomCalc from "../../functions/nodes/nodeIsBottomCalc"
import getNodesExtraInfos from "../../redux/reselect/getNodesExtraInfos"
import SimpleBar from "simplebar-react"

const SheetRows = ({
  projectCurrent,
  nodesExtraInfos,
  statusCurrent,
  params,
  isResetForm = false,
  isExamOn,
  isDebugOn,
  dispatch,
}) => {
  //=================================================================
  const { nodes, rows } = projectCurrent
  const { isStatusLocked } = projectCurrent.setup

  //-----------------------------------------------------------------
  const [sheetHeight, setSheetHeight] = useState(12000)
  const ref = useRef(null)

  useLayoutEffect(() => {
    setSheetHeight(ref.current.clientHeight)
  }, [sheetHeight, projectCurrent])

  //=================================================================
  return (
    //
    <SimpleBar
      style={{
        minHeight: 300,
        maxHeight: sheetHeight,
      }}
    >
      <Box>
        <Box mb={16} mr={2} ref={ref}>
          {rows.map((nId, index) => {
            const node = { ...nodes[nId], ...nodesExtraInfos[nId] }

            return (
              <SheetRow
                key={nId}
                row={index}
                node={node}
                isTop={nodeIsTopCalc(nodes, nId)}
                isBottom={nodeIsBottomCalc(nodes, nId)}
                isStatusLocked={isStatusLocked}
                statusCurrent={statusCurrent}
                params={params}
                dispatch={dispatch}
                isResetForm={isResetForm}
                isExamOn={isExamOn}
                isDebugOn={isDebugOn}
              />
            )
          })}
        </Box>
        <Box h={sheetHeight}></Box>
      </Box>
    </SimpleBar>
  )
}

const mapStateToProps = (state) => {
  return {
    projectCurrent: state.project.statuses[state.project.statusCurrent],
    statusCurrent: state.project.statusCurrent,
    nodesExtraInfos: getNodesExtraInfos(state),
    params: state.project.params,
    isResetForm: state.project.isResetForm,
    isExamOn: state.app.isExamOn,
    isDebugOn: state.app.isDebugOn,
  }
}

export default connect(mapStateToProps)(SheetRows)
