import produce from "immer"
import { scoreInitialStateSet } from "../states/scoreInitialState"
import { scoreAggregate } from "../../functions/score"

import { checkboxUpdate, scoreReset } from "../actions/score"

function score(slice = [], action) {
  const { payload } = action
  let sliceNext = slice

  switch (action.type) {
    ////////////////////////////////////////////////////////////////////////
    case scoreReset.type:
      sliceNext = scoreInitialStateSet()
      return sliceNext
    ////////////////////////////////////////////////////////////////////////
    case checkboxUpdate.type:
      sliceNext = produce(sliceNext, (sliceDraft) => {
        const { qId, answer } = payload
        sliceDraft.questions[qId].answer = answer
        sliceDraft = scoreAggregate(sliceDraft)
      })
      return sliceNext
    default:
      return sliceNext
  }
}

export default score
