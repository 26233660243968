/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Reduce the chosen color by, e.g. 200 => "blue.500" → "blue.300"
 * @param {*} colorStr
 * @param {*} colorReduction
 * @return {string} colorReduction
 */
export const colorReduce = (colorStr = "", colorReduction = 0) => {
  let colorStrNew = colorStr
  let splits = colorStr.split(".")
  colorStrNew =
    splits[0] + "." + (parseInt(splits[1]) - colorReduction).toString()

  return colorStrNew
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Returns a propObject with prop and value for coloring
 * @param {object} parameters to determine coloring on arbitrary propName
 * @return {object} e.g. {color: "blue.500"}
 */
export const levelColorSet = ({
  prop,
  level,
  levelMax,
  isIgnored,
  isUnresolved,
}) => {
  let levelChoice = {}

  switch (levelMax) {
    case 2:
      level === 0
        ? (levelChoice[prop] = "blueGrey.500")
        : level === 1
        ? (levelChoice[prop] = "purple.500")
        : (levelChoice[prop] = "blue.700")
      break
    case 3:
      level === 0
        ? (levelChoice[prop] = "blueGrey.500")
        : level === 1
        ? (levelChoice[prop] = "purple.500")
        : level === 2
        ? (levelChoice[prop] = "blue.700")
        : (levelChoice[prop] = "brown.600")
      break
    case 4:
      level === 0
        ? (levelChoice[prop] = "blueGrey.500")
        : level === 1
        ? (levelChoice[prop] = "purple.500")
        : level === 2
        ? (levelChoice[prop] = "blue.700")
        : level === 3
        ? (levelChoice[prop] = "brown.600")
        : (levelChoice[prop] = "blueGrey.500")
      break
    case 5:
      level === 0
        ? (levelChoice[prop] = "blueGrey.500")
        : level === 1
        ? (levelChoice[prop] = "purple.500")
        : level === 2
        ? (levelChoice[prop] = "purple.300")
        : level === 3
        ? (levelChoice[prop] = "blue.700")
        : level === 4
        ? (levelChoice[prop] = "brown.600")
        : (levelChoice[prop] = "blueGrey.500")
      break
    case 6:
      level === 0
        ? (levelChoice[prop] = "blueGrey.500")
        : level === 1
        ? (levelChoice[prop] = "purple.500")
        : level === 2
        ? (levelChoice[prop] = "purple.300")
        : level === 3
        ? (levelChoice[prop] = "blue.700")
        : level === 4
        ? (levelChoice[prop] = "blue.400")
        : level === 5
        ? (levelChoice[prop] = "brown.600")
        : (levelChoice[prop] = "blueGrey.500")
      break
    default:
      level === 0
        ? (levelChoice[prop] = "blueGrey.500")
        : level === 1
        ? (levelChoice[prop] = "purple.500")
        : level === 2
        ? (levelChoice[prop] = "purple.300")
        : level === 3
        ? (levelChoice[prop] = "blue.700")
        : level === 4
        ? (levelChoice[prop] = "blue.400")
        : level === 5
        ? (levelChoice[prop] = "brown.600")
        : level === 6
        ? (levelChoice[prop] = "brown.400")
        : level === 7
        ? (levelChoice[prop] = "blueGrey.500")
        : level === 8
        ? (levelChoice[prop] = "blueGrey.300")
        : (levelChoice[prop] = "blueGrey.500")
  }

  if (isIgnored || isUnresolved) {
    levelChoice[prop] = "grey.400"
  }

  return levelChoice
}
