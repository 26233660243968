import React from "react"
import { Text, Box } from "@chakra-ui/react"

const Initials = ({ isFirst, initials, boxW, color }) => {
  //=================================================================

  //-----------------------------------------------------------------

  //=================================================================
  if (isFirst) {
    return (
      <Box position="absolute">
        <Text
          fontFamily="Inconsolata"
          position="relative"
          left={-boxW * 1.4 + "px"}
          fontSize={boxW}
          color={color}
        >
          {initials}
        </Text>
      </Box>
    )
  } else {
    return null
  }
}

export default Initials
