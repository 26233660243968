import { ROOT } from "../../const/globals"

/**
 * (c) Jasper Anders
 *
 * Function returns a list of nodes considering if nodes are folded.
 * Starting at any entry node present in a given tree.
 * @param {*} nodes - nodes tree
 * @param {*} nId - node Id entry
 * @param {boolean} isFoldedIgnore - boolean that ignores the fold property
 * @return {array} list of nodes
 */
export function nodesRowsGen(nodes, nId = ROOT, isFoldedIgnore = false) {
  // Extract properties of a node
  const { children, isFolded } = nodes[nId]
  // check if node is either folded or has no children
  if (!!children.length && (!isFolded || isFoldedIgnore)) {
    return [
      nId,
      // for each child recursively call nodesRowsGen
      ...children.map((childId) => nodesRowsGen(nodes, childId)),
    ].flat() // make array flat. E.g: [a, [b, c]] -> [a, b, c]
  }
  // if folded or no children present return nId
  return [nId]
}

/**
 * (c) Jasper Anders
 *
 * recalculation of the whole tree doesn't make sense
 * if only a single node was expanded. Instead keep the
 * existing listNodesFolded and expand the changed Node. The node must
 * be isFolded===false first.
 * @param {object} nodes
 * @param {string} nId
 * @param {array} listNodesFolded
 * @return {array} listNodesFoldedNew
 */
export function nodesUnfold(nodes, nId, listNodesFolded) {
  // find index of the node that should be opened
  const indexUnfold = listNodesFolded.indexOf(nId)
  // create copy of listNodesFolded
  let listNodesFoldedNew = [...listNodesFolded]
  // insert nodesRowsGen
  listNodesFoldedNew.splice(indexUnfold, 1, nodesRowsGen(nodes, nId))
  // flatten array. E.g: [a, [b, c]] -> [a, b, c]
  return listNodesFoldedNew.flat()
}

export default nodesRowsGen
