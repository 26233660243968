import React, { useRef, useState, useLayoutEffect } from "react"
import { Box, Text } from "@chakra-ui/react"
import ScoreQuestionnaire from "../../components/ScoreQuestionnaire"
import { VERSION_SCORE } from "../../const/versions"
import ScoreIntro from "./ScoreIntro"

import SimpleBar from "simplebar-react"

const ScoreQuestionnaireSimpleBar = () => {
  //=================================================================
  const [questionnaireHeight, setQuestionnaireHeight] = useState(12000)
  const ref = useRef(null)

  useLayoutEffect(() => {
    setQuestionnaireHeight(ref.current.clientHeight)
  }, [questionnaireHeight])

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <SimpleBar
      style={{
        minHeight: 700,
        maxHeight: questionnaireHeight,
      }}
    >
      <Box ref={ref}>
        <ScoreIntro />

        <Text textAlign="right" mr={4}>
          {VERSION_SCORE}
        </Text>
        <ScoreQuestionnaire mr={4} />
      </Box>
      <Box h={questionnaireHeight} background="#f8f8f8" mr={4} />
    </SimpleBar>
  )
}

export default ScoreQuestionnaireSimpleBar
