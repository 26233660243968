import React, { useRef, useEffect } from "react"
import { useForm } from "react-hook-form"
import {
  chakra,
  Input,
  Text,
  Flex,
  Box,
  Select,
  Tooltip,
} from "@chakra-ui/react"
import {
  formDatesUpdate,
  selectStatusUpdate,
} from "../../redux/actions/project"

import { LabelTop } from "../../componentsWithChakra"
import ISOToLoc from "../../functions/timeHandler/ISOToLoc"
import objDeepTrim from "../../functions/utils/objDeepTrim"

import {
  DATE_MESSAGE,
  DATE_REGEX,
  DAY_START_REGEX,
  DAY_START_MESSAGE,
  DAY_END_REGEX,
  DAY_END_MESSAGE,
} from "../../const/messages"
import { ROOT } from "../../const/globals"

const FormDates = ({ project, isResetForm = false, dispatch }) => {
  //=================================================================
  const { statusCurrent, statusNext, statusNextPlanned } = project

  const inputRef = useRef(null)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const { statusCompleted } = project.statuses[statusCurrent].setup

  const dateStart = project.statuses[statusCurrent].nodes[ROOT].fromWhen

  const isStatusCompleted = statusCompleted === "Is Completed"

  const { dayStart, dayEnd, dateFormat } = project.params

  //-----------------------------------------------------------------
  useEffect(() => {
    if (isResetForm) {
      reset()
    }
  }, [isResetForm, reset, project])

  //-----------------------------------------------------------------
  const onSubmit = (data) => {
    inputRef.current.focus()

    if (data.statusNext === undefined) {
      data.statusNext = ""
    }
    if (data.statusNextPlanned === undefined) {
      data.statusNextPlanned = ""
    }
    objDeepTrim(data)
    let payload = { data }
    dispatch(formDatesUpdate(payload))
  }

  const onChange = (event) => {
    const payload = { statusCompleted: event.target.value }

    dispatch(selectStatusUpdate(payload))
  }

  //=================================================================
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Flex wrap="wrap" mb={4}>
        <Flex w={["100%", "50%"]} wrap="wrap">
          <Box w={["50%"]} pl={[0, 0, 0, 0]} pr={[1, 1, 1, 1]} order={[1]}>
            <LabelTop>Current Status Date</LabelTop>
            <Text>{ISOToLoc(statusCurrent, "yyyy-MM-dd")}</Text>
          </Box>

          <Box w={["50%"]} pl={[0, 0, 0, 0]} pr={[1, 1, 1, 1]} order={[3]}>
            <LabelTop>Current Status</LabelTop>
            <Select
              name="statusCompleted"
              value={statusCompleted}
              onChange={onChange}
            >
              <option value="In Progress">In Progress</option>
              <option value="Is Completed">Is Completed</option>
            </Select>
          </Box>

          <Box w={["50%"]} pl={[1, 1, 1, 1]} pr={[1, 1, 1, 1]} order={[2]}>
            <LabelTop>Planned Next Status Date</LabelTop>
            <Tooltip
              isOpen={errors.statusNextPlanned}
              label={
                errors.statusNextPlanned ? errors.statusNextPlanned.message : ""
              }
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                {...register("statusNextPlanned", {
                  pattern: {
                    value: DATE_REGEX,
                    message: DATE_MESSAGE,
                  },
                })}
                defaultValue={ISOToLoc(statusNextPlanned, "yyyy-MM-dd")}
              />
            </Tooltip>
            <Text></Text>
          </Box>

          <Box w={["50%"]} pl={[1, 1, 1, 1]} pr={[1, 1, 1, 1]} order={[4]}>
            <Tooltip
              label="Before you can set a Confirmed Next Status Date you will have to set the Current Status from 'In Progress' to 'Is Completed'."
              fontSize="sm"
              placement="top-start"
              hasArrow
            >
              <LabelTop
                color={!isStatusCompleted ? "deepOrange.200" : "deepOrange.500"}
              >
                Confirmed Next Status Date
              </LabelTop>
            </Tooltip>

            <Tooltip
              isOpen={errors.statusNext}
              label={errors.statusNext ? errors.statusNext.message : ""}
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                {...register("statusNext", {
                  pattern: {
                    value: DATE_REGEX,
                    message: DATE_MESSAGE,
                  },
                })}
                defaultValue={ISOToLoc(statusNext, "yyyy-MM-dd")}
                isDisabled={statusCompleted === "In Progress"}
              />
            </Tooltip>
          </Box>
        </Flex>

        <Flex w={["100%", "50%"]} wrap="wrap">
          <Box w={["50%"]} pl={[1, 1, 1, 1]} pr={[1, 1, 1, 1]} order={[1]}>
            <Tooltip
              label="START DATE: the start date of your project."
              fontSize="sm"
              placement="top-start"
              hasArrow
            >
              <LabelTop>Start Date</LabelTop>
            </Tooltip>
            <Tooltip
              isOpen={errors.dateStart}
              label={errors.dateStart ? errors.dateStart.message : ""}
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                {...register("dateStart", {
                  required: "Invalid: Please enter a date.",
                  pattern: {
                    value: DATE_REGEX,
                    message: DATE_MESSAGE,
                  },
                })}
                defaultValue={ISOToLoc(dateStart, "yyyy-MM-dd")}
              />
            </Tooltip>
          </Box>

          <Box w={["50%"]} pl={[1]} pr={[1]} order={[3]}>
            <LabelTop flex="none">Date Format</LabelTop>
            <Text>{dateFormat}</Text>
            {/* <Input name="dateFormat" defaultValue={dateFormat} ref={register} /> */}
          </Box>

          <Box w={["50%"]} pl={[1, 1, 1, 1]} pr={[0, 0, 0, 0]} order={[2]}>
            <LabelTop flex="none">Day Start h</LabelTop>

            <Tooltip
              isOpen={errors.dayStart}
              label={errors.dayStart ? errors.dayStart.message : ""}
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                {...register("dayStart", {
                  required: "Invalid: Please enter a date.",
                  pattern: {
                    value: DAY_START_REGEX,
                    message: DAY_START_MESSAGE,
                  },
                })}
                defaultValue={dayStart}
              />
            </Tooltip>
          </Box>

          <Box w={["50%"]} pl={[1, 1, 1, 1]} pr={[0]} order={[4]}>
            <LabelTop flex="none">Day End h</LabelTop>
            <Tooltip
              isOpen={errors.dayEnd}
              label={errors.dayEnd ? errors.dayEnd.message : ""}
              placement="top"
              hasArrow
              bg="red.700"
            >
              <Input
                {...register("dayEnd", {
                  required: "Invalid: Please enter a date.",
                  pattern: {
                    value: DAY_END_REGEX,
                    message: DAY_END_MESSAGE,
                  },
                })}
                defaultValue={dayEnd}
              />
            </Tooltip>
          </Box>
        </Flex>
      </Flex>

      <Box w="100%">
        <chakra.button type="submit" ref={inputRef} outline="none">
          OK
        </chakra.button>
      </Box>
    </form>
  )
}

export default FormDates
