import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import SEO from "../../components/SEO"
import { Container, Heading, Text } from "@chakra-ui/react"

import { isFactoryResetUpdate } from "../../redux/actions/app"

import { resetProject } from "../../redux/actions/project"
import { settingsReset } from "../../redux/actions/settings"

const PageFactoryReset = ({ persistor, dispatch }) => {
  // ==========================================================================
  const history = useHistory()

  useEffect(() => {
    const purge = () => {
      persistor.purge()
      let payload = { isFactoryReset: true }
      dispatch(isFactoryResetUpdate(payload))
      dispatch(resetProject())
      dispatch(settingsReset())
      console.log("Factory reset performed.")
      history.push("/")
    }

    setTimeout(() => purge(), 200)
  })

  // ==========================================================================
  return (
    <Container minHeight={"640px"} maxW="1200px" mb={4}>
      <SEO
        title="Factory Reset | THE PROJECT STATUS"
        urlPage="/factory-reset"
        description="Reset your page to delete the user date you have set up in THE PROJECT STATUS from you browser. Factory Reset is also useful in the unlikely event of crashes."
      ></SEO>
      <Heading>Factory Reset</Heading>
      <Text>Factory reset was performed.</Text>
    </Container>
  )
}

export default connect()(PageFactoryReset)
