import React from "react"

const CustomClearText = () => "×" //•

const CustomClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props
  return (
    <span
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props)}
    >
      <div
        style={{
          position: "absolute",
          top: "-8px",
          left: " -10px",
          padding: "0",
          margin: "0",
        }}
      >
        {children}
      </div>
    </span>
  )
}

export default CustomClearIndicator
