const Divider = {
  baseStyle: {
    opacity: 1,
    mb: 2,
    mt: 4,
    borderTopColor: "inherit",
    borderTopWidth: "2px",
    borderColor: "initial",
  },
  variants: {
    solid: {
      borderTopStyle: "solid",
    },
    dashed: { borderStyle: "dashed" },
  },
  defaultProps: { variant: "solid" },
}

export default Divider
