// https://www.gridlover.net/try
// Font Size: 16
// Line Height: 1.5
// Scale Factor: 1.414 aug. forth
// Line heights and margins not yet applied

// It seems that it is necessary to explicitly define Headings in theme
// otherwise textStyles do not override the default styles

const textStyles = {
  h1: {
    fontSize: ["45px"],
    color: "blue.800",
  },
  h2: {
    fontSize: ["32px"],
    color: "blue.800",
  },
  h3: {
    fontSize: ["23px"],
    color: "blue.800",
  },
  h4: {
    fontSize: ["16px"],
    color: "blue.800",
  },
  h5: {
    fontSize: ["16px"],
    color: "blue.800",
  },
  h6: {
    fontSize: ["16px"],
    color: "blue.800",
  },
}

export default textStyles
