import { HOURS_PER_DAY } from "../../const/globals"

/**
 * v1.0.0: (c) Jasper Anders
 * v1.1.0: (c) Prof. Dr. Ulrich Anders
 *
 * Transforms hours into a duration string
 * @param {int} hours
 * @param {object} params
 * @returns (object) duration
 */
export function hoursToDur(
  hours,
  { hoursPerDay = HOURS_PER_DAY, daysPerWeek = 5, daysPerMonth = 22 } = {}
) {
  let days = 0
  let hoursRest = 0

  hoursRest = hours % hoursPerDay
  days =
    Math.sign(hours / hoursPerDay) * Math.floor(Math.abs(hours / hoursPerDay))

  let duration = {
    days: days,
    hours: hoursRest,
  }
  return duration
}

/**
 * (c) Jasper Anders
 * (c) Prof. Dr. Ulrich Anders
 *
 * Transforms a duration object into a durationStr
 * @param {object} duration
 * @param {object} params
 * @return {string} durationStr
 */
export function durToDurStr(
  { days, hours },
  { daysPerWeek = 5, daysPerMonth = 22 } = {}
) {
  const daysPerYear = daysPerMonth * 12

  let hoursTotal = days * HOURS_PER_DAY + hours
  days = Math.floor(hoursTotal / HOURS_PER_DAY)
  hours = hoursTotal % HOURS_PER_DAY

  let isNegative = false
  if (hoursTotal < 0) {
    isNegative = true
    hours = Math.abs(hours)
    days = Math.abs(days)
  }

  if (days >= 1 && hours === HOURS_PER_DAY) {
    hours = 0
    days++
  }

  let years = 0
  let months = 0
  let weeks = 0

  // 352days = 16 months
  if (days > 352) {
    years = Math.floor(days / daysPerYear)
    days = days - years * daysPerYear
  }

  // 50days = 10w
  if (days > 50) {
    months = Math.floor(days / daysPerMonth)
    days = days - months * daysPerMonth
  }

  if (days / daysPerWeek >= 2) {
    weeks = Math.floor(days / daysPerWeek)
    days = days - weeks * daysPerWeek
  }

  let durStr =
    (years > 0 ? years + "y" : "") +
    (months > 0 ? months + "m" : "") +
    (weeks > 0 ? weeks + "w" : "") +
    (days > 0 ? days + "d" : "") +
    (hours > 0 ? hours : "")

  if (isNegative) {
    durStr = "-" + durStr
  }

  if (durStr === "1d") {
    durStr = HOURS_PER_DAY.toString()
  }

  if (durStr === "") {
    durStr = "0"
  }

  return durStr
}

/**
 * (c) Jasper Anders
 *
 * Transforms a duration = {years, months, weeks, days, hours}
 * to hours based on params.
 * Defaults are: {hoursPerDay = 8, daysPerWeek = 5, daysPerMonth = 22}
 * @param {object} duration
 * @param {object} params
 * @returns {int} hoursNew
 */
export function durToHours(
  { years = 0, months = 0, weeks = 0, days = 0, hours = 0 } = {},
  { hoursPerDay = HOURS_PER_DAY, daysPerWeek = 5, daysPerMonth = 22 } = {}
) {
  let hoursNew = 0
  const daysPerYear = daysPerMonth * 12

  hoursNew =
    years * daysPerYear * hoursPerDay +
    months * daysPerMonth * hoursPerDay +
    weeks * daysPerWeek * hoursPerDay +
    days * hoursPerDay +
    hours

  return hoursNew
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Transforms a duration string into hours
 * @param {*} durStr
 * @param {*} params
 * @returns {int} returns hoursNew
 */
export function durStrToHours(durStr, params = {}) {
  if (durStr === undefined || durStr.length === 0) {
    return 0
  }

  let splits

  let years = 0
  let months = 0
  let weeks = 0
  let days = 0
  let hours = 0

  if (durStr.length > 0 && durStr.indexOf("y") > -1) {
    splits = durStr.split("y")
    years = parseInt(splits[0])
    durStr = splits[1]
  }

  if (durStr.length > 0 && durStr.indexOf("m") > -1) {
    splits = durStr.split("m")
    months = parseInt(splits[0])
    durStr = splits[1]
  }

  if (durStr.length > 0 && durStr.indexOf("w") > -1) {
    splits = durStr.split("w")
    weeks = parseInt(splits[0])
    durStr = splits[1]
  }

  if (durStr.length > 0 && durStr.indexOf("d") > -1) {
    splits = durStr.split("d")
    days = parseInt(splits[0])
    durStr = splits[1]
  }

  if (durStr.length > 0 && durStr.indexOf("h") > -1) {
    splits = durStr.split("h")
    hours = parseInt(splits[0])
  } else if (durStr.length > 0) {
    hours = parseInt(durStr)
  }

  return durToHours({ years, months, weeks, days, hours }, params)
}

/**
 * (c) Jasper Anders
 *
 * Transform any duration string into the "official" duration string
 * @params {string} durString
 * @returns {string} durString
 */
export function durStrToDur(durStr) {
  return hoursToDur(durStrToHours(durStr))
}
