import React from "react"
import { chakra, Flex, Box, Text } from "@chakra-ui/react"
import format from "date-fns/format"
import isSameDay from "date-fns/isSameDay"
import parseISO from "date-fns/parseISO"
import getISOWeek from "date-fns/getISOWeek"

const GanttTimeLine = ({
  timeLine,
  statusesList,
  statusCurrent,
  dateStart,
  dateEnd,
  boxW,
  ganttDurationIndex,
}) => {
  //=================================================================

  //-----------------------------------------------------------------

  //=================================================================

  const isViewWeek = ganttDurationIndex === 1

  let multiplier = 1
  if (isViewWeek) {
    multiplier = 2
  }
  let weekPrevious = 0
  let monthPrevious = 0

  const renderTimeLine = () => {
    let renderedTimeLine = timeLine.map((el, index) => {
      // is el.date on the same day as the statusCurrent
      const isStatusCurrent = isSameDay(
        parseISO(el.date),
        parseISO(statusCurrent)
      )

      let isStatusDate = isStatusCurrent
      // is el.date same as any dateStatus in statusesList
      if (!isStatusCurrent) {
        isStatusDate = statusesList.some((dateStatus) => {
          const result = isSameDay(parseISO(dateStatus), parseISO(el.date))
          return result
        })
      }

      let isStatusWeek = false
      let isStatusCurrentWeek = false
      if (isViewWeek) {
        isStatusCurrentWeek =
          getISOWeek(parseISO(statusCurrent)) === el.weekOfYear

        isStatusWeek = isStatusCurrentWeek
        if (!isStatusCurrentWeek) {
          isStatusWeek = statusesList.some((dateStatus) => {
            const result = getISOWeek(parseISO(dateStatus)) === el.weekOfYear
            return result
          })
        }
      }

      // only show if isViewWeek and new week otherwise return null
      if (isViewWeek && weekPrevious !== el.weekOfYear) {
        weekPrevious = el.weekOfYear

        let isMonthNew = false
        if (monthPrevious !== el.monthOfYear) {
          // console.log({ monthPrevious, monthCurrent: el.monthOfYear })
          isMonthNew = true
          monthPrevious = el.monthOfYear
        } else {
          isMonthNew = false
        }

        // if (isStatusWeek) {
        return (
          <chakra.span
            fontFamily="mono"
            minW={boxW * multiplier + "px"}
            w={boxW * multiplier + "px"}
            // fontSize="xs"
            fontSize={boxW + "px"}
            key={el.index}
            mr="2px"
            display="inline-block"
            textAlign="left"
            flex="0 0 auto"
            color={
              isStatusCurrentWeek
                ? "pink.500"
                : isStatusWeek
                ? "blue.500"
                : "inherit"
            }
          >
            {index === 0 || isMonthNew ? (
              <Text>
                {format(parseISO(el.date), "M").padStart(
                  2,
                  String.fromCharCode(0x00a0) // ← &nbsp;
                )}
              </Text>
            ) : isStatusWeek ? (
              <Text textAlign="center">|</Text>
            ) : (
              ""
            )}
            <Text textAlign="left">
              {format(parseISO(el.date), "d").padStart(
                2,
                String.fromCharCode(0x00a0) // ← &nbsp;
              )}
            </Text>
          </chakra.span>
        )
      }
      // show for days
      else if (!isViewWeek) {
        return (
          <chakra.span
            fontFamily="mono"
            minW={boxW * multiplier + "px"}
            w={boxW * multiplier + "px"}
            // fontSize="xs"
            fontSize={boxW + "px"}
            key={el.index}
            mr="2px"
            display="inline-block"
            textAlign="right"
            flex="0 0 auto"
            color={
              el.isWeekend
                ? "grey.500"
                : isStatusCurrent
                ? "pink.500"
                : isStatusDate
                ? "blue.500"
                : "inherit"
            }
          >
            {index === 0 || format(parseISO(el.date), "d") === "1" ? (
              <Text>{format(parseISO(el.date), "M")}</Text>
            ) : isStatusCurrent || isStatusDate ? (
              <Text textAlign="center">|</Text>
            ) : (
              ""
            )}
            {format(parseISO(el.date), "d")}
          </chakra.span>
        )
      } else {
        return null
      }
    })

    return renderedTimeLine
  }

  return (
    <Flex mb={1} align="flex-end">
      <Box
        // 196 = 180 + 16 for Caret
        minW={180 + boxW + "px"}
        w={180 + boxW + "px"}
        fontFamily="mono"
        fontSize={boxW + "px"}
        flex="0 0"
      >
        <Text>Month</Text>
        {isViewWeek ? <Text>Week</Text> : <Text>Day</Text>}
      </Box>
      {renderTimeLine()}
    </Flex>
  )
}

export default GanttTimeLine
