import React, { useEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import { chakra, Flex, Input, Tooltip, Box, Avatar } from "@chakra-ui/react"
import { LabelTop } from "../../componentsWithChakra"
import {
  personAdd,
  personRemove,
  personUpdate,
} from "../../redux/actions/project"
import SelectRoles from "../SelectRoles"

import objDeepTrim from "../../functions/utils/objDeepTrim"

import { BiPlus, BiMinus } from "react-icons/bi"
import {
  NAME_MESSAGE,
  NAME_REGEX,
  EMAIL_MESSAGE,
  EMAIL_REGEX,
} from "../../const/messages"

const Person = ({
  person,
  isLast,
  isLocked,
  isResetForm = false,
  dispatch,
}) => {
  //=================================================================
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm()
  const inputRef = useRef(null)

  //-----------------------------------------------------------------
  const {
    sId,
    academicTitle,
    nameFirst,
    nameLast,
    email,
    phone,
    url,
    company,
    jobFunction,
    roles,
    avatar,
  } = person

  //-----------------------------------------------------------------
  useEffect(() => {
    if (isResetForm) {
      reset()
    }
  }, [isResetForm, reset, person])

  //-----------------------------------------------------------------
  const onSubmit = (data) => {
    inputRef.current.focus()
    data.sId = sId
    objDeepTrim(data)
    const payload = { sId, data }
    dispatch(personUpdate(payload))
  }

  // ----------------------------------------------------------------
  const onClickPlus = () => {
    dispatch(personAdd())
  }
  const onClickMinus = () => {
    const payload = { sId }
    dispatch(personRemove(payload))
  }

  //=================================================================
  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Flex align="center">
        <Avatar
          size="xl"
          name={nameFirst + " " + nameLast}
          src={avatar}
          mr={4}
        />

        <Flex wrap="wrap">
          <Box
            order={[2, 2, 1, 1, 1]}
            w={["50%", "25%", "20%", "10%", "8%"]}
            pl={[1, 1, 0, 0, 0]}
            pr={[0, 1, 1, 1, 1]}
          >
            <Tooltip
              label="You may want to enter an academic or other title."
              placement="top-start"
              hasArrow
            >
              <LabelTop>Title</LabelTop>
            </Tooltip>
            <Input
              {...register("academicTitle")}
              defaultValue={academicTitle}
              isDisabled={isLocked}
            />
          </Box>
          <Box
            order={[3, 3, 2, 2, 2]}
            w={["100%", "50%", "40%", "30%", "19%"]}
            pl={[0, 1, 1, 1, 1]}
            pr={[0, 1, 1, 1, 1]}
          >
            <Tooltip
              label="Enter the first name."
              placement="top-start"
              hasArrow
            >
              <LabelTop>First Name</LabelTop>
            </Tooltip>
            <Tooltip
              isOpen={errors.nameFirst}
              label={errors.nameFirst ? errors.nameFirst.message : ""}
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                {...register("nameFirst", {
                  required: "Invalid: Please enter at least one character.",
                  minLength: 1,
                  pattern: {
                    value: NAME_REGEX,
                    message: NAME_MESSAGE,
                  },
                })}
                defaultValue={nameFirst}
                isDisabled={isLocked}
              />
            </Tooltip>
          </Box>
          <Box
            order={[4, 4, 3, 3, 3]}
            w={["100%", "50%", "40%", "30%", "19%"]}
            pl={[0, 0, 1, 1, 1]}
            pr={[0, 1, 0, 1, 1]}
          >
            <Tooltip
              label="Enter the last name."
              placement="top-start"
              hasArrow
            >
              <LabelTop>Last Name</LabelTop>
            </Tooltip>

            <Tooltip
              isOpen={errors.nameLast}
              label={errors.nameLast ? errors.nameLast.message : ""}
              placement="bottom-start"
              hasArrow
              bg="red.500"
            >
              <Input
                {...register("nameLast", {
                  required: "Invalid: Please enter at least one character.",
                  minLength: 1,
                  pattern: {
                    value: NAME_REGEX,
                    message: NAME_MESSAGE,
                  },
                })}
                defaultValue={nameLast}
                isDisabled={isLocked}
              />
            </Tooltip>
          </Box>
          <Box
            order={[5, 6, 8, 4, 4]}
            w={["100%", "50%", "40%", "30%", "19%"]}
            pl={[0, 0, 1, 1, 1]}
            pr={[0, 1, 1, 0, 1]}
          >
            <Tooltip
              label="You can enter the company the person is working for."
              placement="top"
              hasArrow
            >
              <LabelTop>Company</LabelTop>
            </Tooltip>
            <Input
              {...register("company")}
              defaultValue={company}
              isDisabled={isLocked}
            />
          </Box>
          <Box
            order={[6, 7, 8, 8, 5]}
            w={["100%", "50%", "40%", "30%", "19%"]}
            pl={[0, 1, 1, 1, 1]}
            pr={[0, 0, 0, 0, 1]}
          >
            <Tooltip
              label="You can enter the function or job title of the person in the company."
              placement="top"
              hasArrow
            >
              <LabelTop>Function / Job Title</LabelTop>
            </Tooltip>
            <Input
              {...register("jobFunction")}
              defaultValue={jobFunction}
              isDisabled={isLocked}
            />
          </Box>
          <Box
            order={[7, 9, 7, 7, 6]}
            w={["100%", "50%", "20%", "30%", "16%"]}
            pl={[0, 1, 0, 1, 1]}
            pr={[0, 0, 1, 0, 0]}
          >
            <Tooltip
              label="You may enter the phone number. If it is a personal phone number make sure that the person agrees to sharing this information."
              placement="top"
              hasArrow
            >
              <LabelTop>Phone</LabelTop>
            </Tooltip>
            <Input
              {...register("phone")}
              defaultValue={phone}
              isDisabled={isLocked}
            />
          </Box>

          <Box
            order={[1, 1, 4, 5, 7]}
            w={["50%", "25%", "20%", "10%", "8%"]}
            pl={[0, 0, 0, 0, 0]}
            pr={[1, 1, 1, 1, 1]}
          >
            <Tooltip
              label="If you provide a URL to your profile picture it will be displayed. Otherwise the initials are shown."
              placement="top-start"
              hasArrow
            >
              <LabelTop>Avatar-URL</LabelTop>
            </Tooltip>
            <Input
              color="grey.400"
              {...register("avatar")}
              defaultValue={avatar}
              isDisabled={isLocked}
            />
          </Box>
          <Box
            order={[10, 10, 10, 9, 8]}
            w={["100%", "100%", "100%", "70%", "57%"]}
            pl={[0, 0, 0, 0, 1]}
            pr={[0, 0, 0, 1, 1]}
          >
            <Tooltip
              label="You can enter the role the person has in this project. The role can be one of the pre-defined roles from the Setup page or any other role that is useful in this project.!!!"
              placement="top"
              hasArrow
            >
              <LabelTop>Project Roles</LabelTop>
            </Tooltip>
            <SelectRoles
              wId={sId}
              name="roles"
              value={roles}
              isLocked={isLocked}
            />
          </Box>
          <Box
            order={[8, 8, 5, 6, 9]}
            w={["100%", "50%", "40%", "30%", "19%"]}
            pl={[0, 0, 1, 1, 1]}
            pr={[0, 1, 1, 1, 1]}
          >
            <Tooltip label="Enter the email." placement="top" hasArrow>
              <LabelTop>Email</LabelTop>
            </Tooltip>

            <Tooltip
              isOpen={errors.email}
              label={errors.email ? errors.email.message : ""}
              placement="top"
              hasArrow
              bg="red.500"
            >
              <Input
                {...register("email", {
                  required: false,
                  pattern: {
                    value: EMAIL_REGEX,
                    message: EMAIL_MESSAGE,
                  },
                })}
                defaultValue={email}
                isDisabled={isLocked}
              />
            </Tooltip>
          </Box>
          <Box
            order={[9, 5, 6, 10, 10]}
            w={["100%", "50%", "40%", "30%", "16%"]}
            pl={[0, 1, 1, 1, 1]}
            pr={[0, 0, 0, 0, 0]}
          >
            <Tooltip
              label="You can enter a webpage or any other URL of this person as a reference."
              placement="top"
              hasArrow
            >
              <LabelTop>URL</LabelTop>
            </Tooltip>
            <Input
              {...register("url")}
              defaultValue={url}
              isDisabled={isLocked}
            />
          </Box>
        </Flex>
      </Flex>
      {!isLocked && (
        <Box
          width={"80px"}
          justify="space-between"
          px={2}
          mb={4}
          align="flex-start"
          ml="108px"
        >
          <chakra.button type="submit" ref={inputRef} outline="none" mr={2}>
            OK
          </chakra.button>
          {!isLast ? (
            <chakra.button
              type="button"
              onClick={onClickMinus}
              cursor="pointer"
              outline="none"
            >
              <Box position="relative" top="-4px">
                <BiMinus />
              </Box>
            </chakra.button>
          ) : (
            <chakra.span w="16px"></chakra.span>
          )}
          {isLast ? (
            <chakra.button
              type="button"
              onClick={onClickPlus}
              cursor="pointer"
              outline="none"
            >
              <Box position="relative" top="-2px">
                <BiPlus />
              </Box>
            </chakra.button>
          ) : (
            <chakra.span w="16px"></chakra.span>
          )}
        </Box>
      )}
    </form>
  )
}

export default Person
