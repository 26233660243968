import React from "react"
import { connect } from "react-redux"
import { Box } from "@chakra-ui/react"
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ResponsiveContainer,
  Label,
} from "recharts"

import TRANSLATIONS from "../../locales/en/translations"

const ScoreRadar = ({ score }) => {
  //=================================================================

  const scoreData = score.questions["00"].children.map((cId, index) => {
    let obj = {
      component: cId + " " + TRANSLATIONS.questions[cId],
      scorePercent: score.questions[cId].scorePercent,
    }
    return obj
  })

  //-----------------------------------------------------------------
  let aspectRadar = 1.6

  //=================================================================
  return (
    <Box
      width="100%"
      h={["400px", "400px", "400px", "400px", "500px", "600px"]}
    >
      <ResponsiveContainer
        width="100%"
        height="100%"
        minWidth={100}
        minHeight={100}
        aspect={aspectRadar}
      >
        <RadarChart
          outerRadius={"75%"}
          innerRadius={"20%"}
          width={"100%"}
          height={"100%"}
          data={scoreData}
          margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
        >
          <PolarGrid />
          <PolarAngleAxis dataKey="component" />
          <PolarRadiusAxis
            angle={90}
            domain={[0, 100]}
            type="number"
            scale="linear"
            tickCount={4}
          >
            <Label
              position="center"
              style={{
                fill: "#000",
                // App.css: tspan needs to inherit all font values
                fontFamily: "Fira Sans Condensed",
                fontSize: "36px",
                fontWeight: "500",
              }}
            >
              {score.questions["00"].scorePercent.toString()}
            </Label>
          </PolarRadiusAxis>
          <Radar
            name="SCORE"
            dataKey="scorePercent"
            stroke="#1e88e5"
            fill="#1e88e5"
            fillOpacity={0.6}
          />
        </RadarChart>
      </ResponsiveContainer>
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    score: state.score,
  }
}

export default connect(mapStateToProps)(ScoreRadar)
