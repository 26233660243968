import React from "react"
import PropTypes from "prop-types"
import { Box } from "@chakra-ui/react"
import { LabelCheckbox } from "../../componentsWithChakra"

import Spacer from "./Spacer"

import { checkboxUpdate } from "../../redux/actions/score"

const ScoreAnswers = ({ question, qId, dispatch }) => {
  //======================================================================
  const dispatchOnChange = ({ target }) => {
    const payload = {
      qId,
      answer: parseInt(target.name.split("_")[1]),
    }
    dispatch(checkboxUpdate(payload))
  }

  const answers = ["n.a.", "no", "a little", "rather well", "yes, absolutely"]

  //======================================================================
  return (
    <Box className={"checkbox-blue"}>
      <span style={{ whiteSpace: "nowrap" }}>
        <input
          id={qId + "_0"}
          name={qId + "_0"}
          type="checkbox"
          onChange={dispatchOnChange}
          checked={question.answer === 0}
        />
        <LabelCheckbox htmlFor={qId + "_0"}>n.a.</LabelCheckbox>
      </span>
      <Spacer />
      <span style={{ whiteSpace: "nowrap" }}>
        <input
          id={qId + "_1"}
          name={qId + "_1"}
          type="checkbox"
          onChange={dispatchOnChange}
          checked={question.answer === 1}
        />
        <LabelCheckbox htmlFor={qId + "_1"}>{answers[1]}</LabelCheckbox>
      </span>
      <Spacer />
      <span style={{ whiteSpace: "nowrap" }}>
        <input
          id={qId + "_2"}
          name={qId + "_2"}
          type="checkbox"
          onChange={dispatchOnChange}
          checked={question.answer === 2}
        />
        <LabelCheckbox htmlFor={qId + "_2"}>{answers[2]}</LabelCheckbox>
      </span>
      <Spacer />
      <span style={{ whiteSpace: "nowrap" }}>
        <input
          id={qId + "_3"}
          name={qId + "_3"}
          type="checkbox"
          onChange={dispatchOnChange}
          checked={question.answer === 3}
        />
        <LabelCheckbox htmlFor={qId + "_3"}>{answers[3]}</LabelCheckbox>
      </span>
      <Spacer />
      <span style={{ whiteSpace: "nowrap" }}>
        <input
          id={qId + "_4"}
          name={qId + "_4"}
          type="checkbox"
          onChange={dispatchOnChange}
          checked={question.answer === 4}
        />
        <LabelCheckbox htmlFor={qId + "_4"}>{answers[4]}</LabelCheckbox>
      </span>
    </Box>
  )
}

ScoreAnswers.propTypes = {
  qId: PropTypes.string,
  question: PropTypes.object,
  answers: PropTypes.array,
  style: PropTypes.object,
}

export default ScoreAnswers
