import { ROOT } from "../../const/globals"
/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Calculates, if a node nId is top of the children of its parent
 * @param {object} nodes
 * @param {string} nId
 * @return {boolean}
 */
const nodeIsTopCalc = (nodes, nId) => {
  if (nId === ROOT) {
    return true
  } else {
    const pId = nodes[nId].pId
    if (nodes[pId].children.indexOf(nId) === 0) {
      return true
    }
    return false
  }
}

export default nodeIsTopCalc
