import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet-async"

import {
  META_LANG,
  META_DESCRIPTION,
  META_DOMAIN,
  META_URL_IMAGE,
  META_URL_PAGE,
  META_URL_SITE_TWITTER,
} from "../../const/meta"

import { META_URL_SITE } from "../../const/urlGatsby"

const SEO = ({
  lang,
  title,
  description,
  urlImage,
  keywords,
  meta,
  domain,
  urlSite,
  urlPage,
  urlSiteTwitter,
}) => {
  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        link={[
          {
            rel: "canonical",
            href: urlSite + urlPage,
          },
        ]}
        meta={[
          {
            name: "description",
            content: description,
          },
          // Open Graph / Facebook
          {
            property: "og:url",
            content: urlSite + urlPage,
          },
          {
            property: "og:type",
            content: "website",
          },
          {
            property: "og:title",
            content: title,
          },
          {
            property: "og:description",
            content: description,
          },
          {
            property: "og:image",
            content: urlSite + urlImage,
          },
          {
            property: "og:image:width",
            content: "480",
          },
          {
            property: "og:image:height",
            content: "320",
          },
          // Twitter
          {
            property: "twitter:card",
            content: "summary_large_image",
          },
          {
            property: "twitter:domain",
            content: domain,
          },
          {
            property: "twitter:url",
            content: urlSite + urlPage,
          },
          // {
          //   property: "twitter:site",
          //   content: urlSiteTwitter,
          // },
          {
            property: "twitter:title",
            content: title,
          },
          {
            property: "twitter:description",
            content: description,
          },
          {
            property: "twitter:image",
            content: urlSite + urlImage,
          },
        ]
          .concat(
            keywords.length > 0
              ? {
                  name: "keywords",
                  content: keywords.join(", "),
                }
              : []
          )
          .concat(meta)}
      />
    </React.Fragment>
  )
}

SEO.defaultProps = {
  lang: META_LANG,
  title: "",
  description: META_DESCRIPTION,
  urlImage: META_URL_IMAGE,
  domain: META_DOMAIN,
  urlSite: META_URL_SITE,
  urlPage: META_URL_PAGE,
  urlSiteTwitter: META_URL_SITE_TWITTER,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  urlImage: PropTypes.string,
  urlSite: PropTypes.string,
  urlPage: PropTypes.string.isRequired,
  urlSiteTwitter: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.arrayOf(PropTypes.string),
}

export default SEO
