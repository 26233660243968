import projectInitialState from "./projectInitialState"
import settingsInitialState from "./settingsInitialState"
import scoreInitialState from "./scoreInitialState"
import appInitialState from "./appInitialState"

const initialState = {
  ...projectInitialState,
  ...scoreInitialState,
  ...settingsInitialState,
  ...appInitialState,
}

export default initialState
