import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { createMigrate, persistStore, persistReducer } from "redux-persist"

// middleware
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import preloadedState from "./states/initialState"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"

// middleware
import logger from "redux-logger"

// reducers
import project from "./reducers/project"
import settings from "./reducers/settings"
import score from "./reducers/score"
import app from "./reducers/app"

const middleware = process.env.NODE_ENV !== "production" ? [logger] : []

const reducers = combineReducers({
  project,
  settings,
  score,
  app,
})

// With this I can clear out previous persisted states
const migrations = {
  0: (state) => {
    // migration clear out whole state
    return {
      state: undefined,
      // settings: undefined,
      // project: undefined,
    }
  },
  1: (state) => {
    // migration clear out whole state
    return {
      state: undefined,
      // settings: undefined,
      // project: undefined,
    }
  },
  2: (state) => {
    // migration clear out whole state
    return {
      state: undefined,
      // settings: undefined,
      // project: undefined,
    }
  },
  3: (state) => {
    // migration clear out whole state
    return {
      state: undefined,
      // settings: undefined,
      // project: undefined,
    }
  },
  4: (state) => {
    // migration clear out whole state
    return {
      // state: undefined,
      // settings: undefined,
      project: undefined,
    }
  },
}

const persistConfig = {
  key: "root",
  version: 4,
  storage,
  blacklist: ["app", "score"],
  // stateReconciler: false, // false for no reconciliation
  stateReconciler: autoMergeLevel2, // false for no reconciliation
  migrate: createMigrate(migrations, { debug: false }),
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware,
  devTools: process.env.NODE_ENV !== "production",
  preloadedState,
  //enhancers
})

export const persistor = persistStore(store)

// The store has been created with these options:
// - The slice reducers were automatically passed to combineReducers()
// - redux-thunk and redux-logger were added as middleware
// - The Redux DevTools Extension is disabled for production
// - The middleware, batch, and devtools enhancers were automatically composed together
