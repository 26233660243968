import cloneDeep from "lodash.clonedeep"
import nodesRowsGen from "../nodes/nodesRowsGen"
import { ROOT } from "../../const/globals"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Removes a person sId from a given node and returns the same node
 * @param {object} node
 * @param {string} sId
 * @param {object} node
 */
export const personRemoveFromNodes = (nodes, sId) => {
  let nodesNew = cloneDeep(nodes)
  const nodesAllListed = nodesRowsGen(nodesNew, ROOT, true)

  nodesAllListed.forEach((nId) => {
    personRemoveFromNode(nodes[nId], sId)
  })

  return nodesNew
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Removes a person sId from a given node and returns the same node
 * @param {object} node
 * @param {string} sId
 * @param {object} node
 */
export const personRemoveFromNode = (node, sId) => {
  if (sId === node.who) {
    node.who = ""
  }
  if (sId === node.toWho) {
    node.toWhom = ""
  }

  return node
}

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Removes a person sId from a given setup and returns the same setup
 * @param {object} node
 * @param {string} sId
 * @param {object} node
 */
export const personRemoveFromSetup = (setup, sId) => {
  if (sId === setup.agileMaster) {
    setup.agileMaster = ""
  }
  if (sId === setup.controller) {
    setup.controller = ""
  }
  if (sId === setup.customer1) {
    setup.customer1 = ""
  }
  if (sId === setup.customer2) {
    setup.customer2 = ""
  }
  if (sId === setup.customer2) {
    setup.customer2 = ""
  }
  if (sId === setup.manager) {
    setup.manager = ""
  }
  if (sId === setup.otherVIP) {
    setup.otherVIP = ""
  }
  if (sId === setup.productOwner) {
    setup.productOwner = ""
  }
  if (sId === setup.sponsor) {
    setup.sponsor = ""
  }

  setup.membersTeam = setup.membersTeam.filter((member) => member.value !== sId)

  setup.membersStC = setup.membersStC.filter((member) => member.value !== sId)

  return setup
}
