import { createBreakpoints } from "@chakra-ui/theme-tools"
const breakpoints = createBreakpoints({
  sm: "20.063em", //"321px",
  md: "48em", //"768px",
  lg: "60.063em", //"961px",
  xl: "80.063em", //"1281px",
  "2xl": "100em", // "1600px",
})

// original
//                   0, 480px,  768px,  992px, 1280px,
// const breakpoints = ["0em", "30em", "48em", "62em", "80em"]

export default breakpoints
