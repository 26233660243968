import { createSelector } from "reselect"

const getPersonsCurrent = (state) =>
  state.project.statuses[state.project.statusCurrent].persons

const getOptionsPersons = createSelector(
  [getPersonsCurrent],
  (personsCurrent) => {
    let optionsPersons = Object.keys(personsCurrent)
      .slice()
      .sort((a, b) => {
        return personsCurrent[a].nameDisplayShort >
          personsCurrent[b].nameDisplayShort
          ? 1
          : personsCurrent[b].nameDisplayShort >
            personsCurrent[a].nameDisplayShort
          ? -1
          : 0
      })
      .map((sId) => ({
        value: sId,
        label: personsCurrent[sId].nameDisplayShort,
      }))

    return optionsPersons
  }
)

export default getOptionsPersons
