import React from "react"
import { Flex, Box, Text } from "@chakra-ui/react"
import ScoreRadar from "../../components/ScoreRadar"

const ScoreRadarMaturities = ({ ...rest }) => {
  //=================================================================

  //-----------------------------------------------------------------

  //=================================================================
  return (
    <Box {...rest}>
      <Flex justify="space-around">
        <Box px={1}>
          <Text fontWeight="bold">0 − 20%:</Text>
          <Text fontWeight="medium">LOW MATURITY</Text>
        </Box>
        <Box px={1}>
          <Text fontWeight="bold">21 − 40%:</Text>
          <Text fontWeight="medium">BASIC MATURITY</Text>
        </Box>
        <Box px={1}>
          <Text fontWeight="bold">41 − 60%:</Text>
          <Text fontWeight="medium">MEDIUM MATURITY</Text>
        </Box>
        <Box px={1}>
          <Text fontWeight="bold">61 − 80%:</Text>
          <Text fontWeight="medium">ADVANCED MATURITY</Text>
        </Box>
        <Box px={1}>
          <Text fontWeight="bold">81 − 100%:</Text>
          <Text fontWeight="medium">BEST PRACTICES MATURITY</Text>
        </Box>
      </Flex>

      <ScoreRadar />
    </Box>
  )
}

export default ScoreRadarMaturities
