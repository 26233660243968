import React from "react"
import { connect } from "react-redux"
import { Flex, Box, Heading, Text } from "@chakra-ui/react"
import ScoreAnswers from "../ScoreAnswers"

import TRANSLATIONS from "../../locales/en/translations"

const ScoreQuestionnaire = ({ score, dispatch, ...rest }) => {
  // ==========================================================================
  const { questions } = score

  // ==========================================================================

  const renderQuestions = () => {
    let questionsStack = []
    questions["00"].children.forEach((qId) => {
      questionsStack.push(qId)
      if (questions[qId].children && questions[qId].children.length > 0) {
        questions[qId].children.forEach((qId) => questionsStack.push(qId))
      }
    })

    let renderedQuestions = []
    questionsStack.forEach((qId) => {
      questions[qId].level === 1
        ? renderedQuestions.push(
            <Heading as="h4" fontSize="md" key={qId} mt={4}>
              {qId +
                " " +
                TRANSLATIONS.questions[qId] +
                ": " +
                questions[qId].scorePercent}
            </Heading>
          )
        : renderedQuestions.push(
            <Flex key={qId}>
              <Text ml="21px" minW="42px">
                {qId}
              </Text>
              <Box>
                <Text>
                  {/* {TRANSLATIONS.questions[qId] + ` [${questions[qId].weight}]`} */}
                  {TRANSLATIONS.questions[qId]}
                </Text>
                <ScoreAnswers
                  dispatch={dispatch}
                  qId={qId}
                  question={questions[qId]}
                />
              </Box>
            </Flex>
          )
    })

    return renderedQuestions
  }

  return (
    <Box fontFamily="Fira Sans Condensed" pr={1} {...rest}>
      <form>{renderQuestions()}</form>
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    score: state.score,
  }
}
export default connect(mapStateToProps)(ScoreQuestionnaire)
