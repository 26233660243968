import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"
import {
  Divider,
  useToast,
  Container,
  Box,
  Flex,
  Button,
  useDisclosure,
  AlertDialog,
  AlertDialogCloseButton,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react"
import { RiLockUnlockLine, RiLockLine } from "react-icons/ri"
import SEO from "../../components/SEO"
import StatusTravel from "../../components/StatusTravel"
import FormDates from "../../components/FormDates"
import FormSetup from "../../components/FormSetup"
import FormRatios from "../../components/FormRatios"
import FormParams from "../../components/FormParams"

import {
  resetProject,
  messageFromProjectDelete,
} from "../../redux/actions/project"
import { messageFromAppDelete } from "../../redux/actions/app"

const PageSetup = ({
  project,
  settings,
  isLocked,
  messageFromProject,
  messageFromApp,
  isResetForm = false,
  dispatch,
}) => {
  //=================================================================
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  //-----------------------------------------------------------------
  useEffect(() => {
    if (messageFromProject !== "") {
      const title = messageFromProject.split(": ")[0]
      const description = messageFromProject.substring(
        messageFromProject.indexOf(":") + 1
      )
      const status = title.toLowerCase()
      toast({
        title,
        description,
        status: status ? status : "info",
        duration: 9000,
        isClosable: true,
      })
      dispatch(messageFromProjectDelete())
    }
    if (messageFromApp !== "") {
      const title = messageFromApp.split(": ")[0]
      const description = messageFromApp.substring(
        messageFromApp.indexOf(":") + 1
      )
      toast({
        title,
        description,
        status: "error",
        duration: 30000,
        isClosable: true,
      })
      dispatch(messageFromAppDelete())
    }
  }, [messageFromProject, messageFromApp, toast, dispatch])

  //-----------------------------------------------------------------

  const onClickReset = () => {
    dispatch(resetProject())
    onClose()
  }
  //=================================================================
  return (
    <>
      <Container minH="640px" maxW="1200px">
        <SEO
          title="Setup | THE PROJECT STATUS"
          urlPage="/setup"
          description="Setup your project with respect to status dates, people, start and end dates, supplemented performance numbers and borders for critical evaluations."
        ></SEO>

        <Flex mb={4} justify="space-between" align="flex-end">
          <Flex align="center">
            <Box as="h1" textStyle="h2" mr={2}>
              Project Setup
            </Box>
            {isLocked ? <RiLockLine /> : <RiLockUnlockLine />}
          </Flex>
          <Button
            onClick={onOpen}
            color="white"
            bg="blue.800"
            borderRadius="5px"
            mr={0}
          >
            Reset Project
          </Button>
        </Flex>
        <StatusTravel />
        <FormSetup />
        <Divider />
        <FormRatios
          project={project}
          settings={settings}
          isResetForm={isResetForm}
          dispatch={dispatch}
        />
        <Divider />
        <FormDates
          project={project}
          isResetForm={isResetForm}
          dispatch={dispatch}
        />
        <Divider />
        <FormParams
          project={project}
          settings={settings}
          isResetForm={isResetForm}
          dispatch={dispatch}
        />
      </Container>

      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Confirm Reset Project?</AlertDialogHeader>
          <AlertDialogCloseButton />

          <AlertDialogBody>
            Are you sure you want to reset your whole project? All your
            deliverables and all persons will be deleted.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button color="white" bg="red" ml={3} onClick={onClickReset}>
              Reset
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    project: state.project,
    settings: state.settings,
    isLocked:
      state.project.statuses[state.project.statusCurrent].setup.isStatusLocked,
    messageFromApp: state.app.messageFromApp,
    messageFromProject: state.project.messageFromProject,
    isResetForm: state.project.isResetForm,
  }
}

export default connect(mapStateToProps)(PageSetup)
