import React from "react"
import { Route } from "react-router-dom"
import { Container } from "@chakra-ui/react"
import PageControlSheet from "../../pages/PageControlSheet"
import PageHeader from "../PageHeader"
import PageFooter from "../PageFooter"
import PageSetup from "../../pages/PageSetup"
import PagePersons from "../../pages/PagePersons"
import PageScore from "../../pages/PageScore"
// import PageProgress from "../../pages/PageProgress"
import PageFactoryReset from "../../pages/PageFactoryReset"

function App({ persistor }) {
  return (
    <Container maxW="100%" px={4}>
      <PageHeader />
      <Route exact path="/" component={PageControlSheet} />
      {/* <Route exact path="/progress" component={PageProgress} /> */}
      <Route exact path="/persons" component={PagePersons} />
      <Route exact path="/setup" component={PageSetup} />
      <Route exact path="/score" component={PageScore} />
      <Route
        exact
        path="/factory-reset"
        render={(props) => (
          <PageFactoryReset {...props} persistor={persistor} />
        )}
      />
      <PageFooter />
    </Container>
  )
}

export default App
