import { customAlphabet } from "nanoid"
const idLength = 6

// no: '0', l', 'o'
// always start with a letter
const nanoid1 = customAlphabet("abcdefghijkmpqrstuvwxyz", 1)
const nanoid2 = customAlphabet("abcdefghijkmpqrstuvwxyz123456789", idLength - 1)

const nanoid = () => nanoid1() + nanoid2()

export default nanoid
