const Tooltip = {
  baseStyle: {
    fontSize: "inherit",
    fontWeight: "inherit",
  },
  variants: {
    normal: { fontWeight: "normal" },
  },
}

export default Tooltip
