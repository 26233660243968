const Input = {
  parts: ["field", "addon"],
  baseStyle: {
    field: {
      width: "100%",
      outline: 0,
      position: "relative",
      appearance: "none",
      transition: "all 0.2s",
      fontWeight: "inherit",
    },
  },
  sizes: {
    lg: {
      field: { fontSize: "lg", px: 4, h: 12, borderRadius: "md" },
      addon: { fontSize: "lg", px: 4, h: 12, borderRadius: "md" },
    },
    md: {
      field: { fontSize: "md", px: 4, h: 10, borderRadius: "md" },
      addon: { fontSize: "md", px: 4, h: 10, borderRadius: "md" },
    },
    sm: {
      field: { fontSize: "sm", px: 3, h: 8, borderRadius: "sm" },
      addon: { fontSize: "sm", px: 3, h: 8, borderRadius: "sm" },
    },
    xs: {
      field: { fontSize: "xs", px: 2, h: 6, borderRadius: "sm" },
      addon: { fontSize: "xs", px: 2, h: 6, borderRadius: "sm" },
    },
  },
  variants: {
    unstyled: {
      field: { bg: "transparent", pl: 0, pr: 0, height: "auto" },
      addon: { bg: "transparent", pl: 0, pr: 0, height: "auto" },
    },
    pc: {
      field: {
        bg: "transparent",
        pl: 0,
        pr: 0,
        height: "auto",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderColor: "grey.400",
        borderRadius: "0",
        fontSize: "inherit",
        letterSpacing: "inherit",
      },
      addon: { bg: "transparent", pl: 0, pr: 0, height: "auto" },
    },
  },
  defaultProps: { size: "md", variant: "pc" },
}

export default Input
