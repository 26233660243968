import React from "react"
import { Box, Text } from "@chakra-ui/react"
import ScoreQuestionnaire from "../../components/ScoreQuestionnaire"
import { VERSION_SCORE } from "../../const/versions"
import ScoreRadarMaturities from "./ScoreRadarMaturities"
import ScoreIntro from "./ScoreIntro"

const ScoreQuestionnairePlain = () => {
  //=================================================================
  return (
    <Box>
      <ScoreIntro />

      <ScoreRadarMaturities my={8} />

      <Text textAlign="right" mr={4}>
        {VERSION_SCORE}
      </Text>
      <ScoreQuestionnaire />
    </Box>
  )
}

export default ScoreQuestionnairePlain
