export const META_APP_NAME = "THE PROJECT STATUS"
export const META_APP_AUTHOR = "Prof. Dr. Ulrich Anders"
export const META_DOMAIN = "project-status.org"
export const META_URL_SITE_TWITTER = ""
export const META_URL_PAGE = "/"
export const META_URL_IMAGE = "/Project-Status-Org.png"
export const META_AUTHOR = "Prof. Dr. Ulrich Anders"
export const META_LANG = "en"
export const META_DESCRIPTION =
  "★★★★★ Make project status reporting easy. Project Management without status reports is like an aeroplane without instruments. You would basically fly blind."
