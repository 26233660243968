import differenceInDays from "date-fns/differenceInDays"
import addDays from "date-fns/addDays"
import isWeekendCalc from "date-fns/isWeekend"
import parseISO from "date-fns/parseISO"
import startOfDay from "date-fns/startOfDay"
import startOfISOWeek from "date-fns/startOfISOWeek"
import getISODay from "date-fns/getISODay"
import getISOWeek from "date-fns/getISOWeek"
import getMonth from "date-fns/getMonth"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Generates a timeLine from a given dateStartISO to a dateEndISO
 * @param {string} dateStartISO
 * @param {string} dateEndISO
 * @returns {array} array of objects with information on index, date and weekend
 */
function timeLineGen(dateStartISO, dateEndISO, isViewWeek = false) {
  let dateStart = startOfDay(parseISO(dateStartISO))
  if (isViewWeek) {
    dateStart = startOfISOWeek(dateStart)
  }

  const dateEnd = startOfDay(parseISO(dateEndISO))

  let daysDiff = differenceInDays(dateEnd, dateStart)
  if (daysDiff < 0) {
    daysDiff = 0
  }

  const timeLine = Array(daysDiff + 1)
    .fill(null)
    .map((el, index) => {
      const date = addDays(dateStart, index)
      const isWeekend = isWeekendCalc(date)

      return {
        index: index,
        date: date.toISOString(),
        dayOfWeek: getISODay(date),
        isWeekend,
        weekOfYear: getISOWeek(date),
        monthOfYear: getMonth(date),
      }
    })

  return { timeLine, weekStartISO: dateStart.toISOString() }
}

export default timeLineGen
