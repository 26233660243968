import { ROOT } from "../../const/globals"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Calculates, if a node nId is the bottom of the children of its parent
 * @param {object} nodes
 * @param {string} nId
 * @return {boolean}
 */
const nodeIsBottomCalc = (nodes, nId) => {
  if (nId === ROOT) {
    return true
  } else {
    const pId = nodes[nId].pId
    if (nodes[pId].children.indexOf(nId) === nodes[pId].children.length - 1) {
      return true
    }
    return false
  }
}

export default nodeIsBottomCalc
