import format from "date-fns/format"
import parseISO from "date-fns/parseISO"

/**
 * (c) Prof. Dr. Ulrich Anders
 *
 * Transforms a dateISOString into a formatted string with local time
 * @param {*} dateISOString
 * @param {*} formatStr
 */
const ISOToLoc = (dateISOString, formatStr = "yyyy-MM-dd|HH") => {
  let loc
  if (dateISOString === "") {
    loc = ""
  } else {
    loc = format(parseISO(dateISOString), formatStr)
  }

  return loc
}

export default ISOToLoc
